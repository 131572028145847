import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import lingon from '../images/lingonberries-in-forest.jpg';

export default function Articles() {
    const [articles, setArticles] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        async function fetchArticle() {
          try {
            const response = await fetch('/articles.json');
            const data = await response.json();
            setArticles(data);
            setIsLoading(false);
          } catch (error) {
            console.error('Error fetching articles:', error);
            setIsLoading(false);
          }
        }
    
        fetchArticle();
    }, []);

    if (isLoading) {
        return (
            <div className="loading-container">
                <h1>Laddar...</h1>
            </div>
        )
    }

    if (articles) {
        return (
            <>
                <Helmet>
                    <title>Artiklar - Berryprice</title>
                    <meta name="description" content="Allt du behöver veta om att plocka och sälja bär samlat på ett ställe. Dagliga inköpspriser, hur man plockar lingon och blåbär, bärhandlare nära dig." />
                </Helmet>
                <div style={{width: "100vw", height: "200px", transform: "translate(0,-20px)"}}><img style={{objectFit: "cover", width: "100%", height: "100%"}} src={lingon} alt="lingon i skogsmiljö"></img></div>
                <div className="article-container">
                    <div className="article">
                        <h1>Artiklar</h1>
                        <p>Allt du behöver veta om att plocka och sälja bär samlat på ett ställe.</p>
                        <Link to={`/`}>
                            <h3>Dagliga inköpspriser för lingon och blåbär</h3>
                        </Link>
                        {Object.keys(articles).map(key => (
                            <Link to={`/articles/${key}`}>
                                <h3>{articles[key].title}</h3>
                            </Link>
                        ))}
                    </div>
                </div>
            </>
        )
    }
}