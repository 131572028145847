
import { Helmet } from "react-helmet-async"

export default function PrivacyPolicy() {
    return (
        <>
        <Helmet>
            <title>Privacy policy - Berryprice</title>
        </Helmet>
        <div className="update" style={{flex: 1}}>
            <h1>Privacy policy</h1>
            <div className="card" style={{maxWidth: 800, textAlign: 'justify'}}>
            <h2>Introduction</h2>
            <p>
                NOS Technology AB ("Company", "we", "our", "us") is committed to protecting and respecting your privacy. This policy outlines our practices regarding the collection, use, and disclosure of your information when you use our services, in compliance with the General Data Protection Regulation (GDPR).
            </p>

            <h2>Information We Collect</h2>
            <ul>
                <li><strong>Personal Data:</strong> Includes but is not limited to name, email address, phone number, and business details provided during registration.</li>
                <li><strong>Usage Data:</strong> Information on how the service is accessed and used, including IP address, browser type, and usage patterns.</li>
            </ul>

            <h2>How We Use Your Information</h2>
            <ul>
                <li><strong>To Provide and Maintain Services:</strong> Ensuring the functionality of our services and providing customer support.</li>
                <li><strong>To Communicate:</strong> Sending notices, updates, and promotional materials related to our services.</li>
                <li><strong>To Improve Services:</strong> Analyzing usage patterns to enhance user experience.</li>
            </ul>

            <h2>Legal Basis for Processing Personal Data</h2>
            <p>We process personal data based on the following legal grounds:</p>
            <ul>
                <li><strong>Consent:</strong> Users have given consent for one or more specific purposes.</li>
                <li><strong>Contract:</strong> Processing is necessary for the performance of a contract with the user.</li>
                <li><strong>Legal Obligation:</strong> Processing is necessary for compliance with a legal obligation.</li>
                <li><strong>Legitimate Interests:</strong> Processing is necessary for the purposes of the legitimate interests pursued by the Company or a third party.</li>
            </ul>

            <h2>Data Sharing and Disclosure</h2>
            <ul>
                <li><strong>Service Providers:</strong> We may employ third-party companies to facilitate our services, which have access to your data only to perform tasks on our behalf.</li>
                <li><strong>Legal Requirements:</strong> We may disclose your information if required by law or in response to valid requests by public authorities.</li>
            </ul>

            <h2>Data Security</h2>
            <p>
                We implement appropriate technical and organizational measures to protect your personal data against unauthorized access, alteration, disclosure, or destruction.
            </p>

            <h2>User Rights Under GDPR</h2>
            <ul>
                <li><strong>Access and Correction:</strong> Users can request access to their personal data and correct any inaccuracies.</li>
                <li><strong>Data Deletion:</strong> Users can request the deletion of their personal data.</li>
                <li><strong>Data Portability:</strong> Users have the right to obtain a copy of their data in a structured, machine-readable format.</li>
                <li><strong>Right to Object:</strong> Users can object to the processing of their personal data.</li>
                <li><strong>Right to Restriction of Processing:</strong> Users can request the restriction of processing of their personal data.</li>
            </ul>

            <h2>Cookies and Tracking Technologies</h2>
            <p>
                We use cookies and similar tracking technologies to track the activity on our service and hold certain information. Users can control cookie preferences through their browser settings.
            </p>

            <h2>Changes to This Privacy Policy</h2>
            <p>
                We may update our Privacy Policy from time to time. Users will be notified of any changes by posting the new Privacy Policy on this page. Users are advised to review this Privacy Policy periodically for any changes.
            </p>

            <h2>Contact Us</h2>
            <p>
                For any questions or concerns regarding this Privacy Policy, please contact us at:
                <br/>
                NOS Technology AB (559438-2482)
                <br/>
                <a href="mailto:info@nostechnology.com">info@nostechnology.com</a>
            </p>
            </div>
        </div>
        </>
    )
}

