
import { t } from "i18next";
import arrow from '../images/Vector 4.svg';
import OpeningHours from "./OpeningHours";

export default function ManageLocation({location, companyId, openLocationId, sendId}) {
    
    async function handleActiveChange(locId, active) {
        const data = {
            company_id: companyId,
            location_id: locId,
            active_bool: active
        }
        const url = "https://bptestapi-e7db5f925e70.herokuapp.com/v4/location_update/";

        try {
            const response = await fetch(url,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
            if (!response.ok) {
                console.log('http error: ', response);
            } else {
                console.log(active ? "Activated successfully" : "Deactivated successfully")
            }
        } catch (error) {
            console.log('error (de)activating: ', error);
        }
    }

    return (
        <div className='manage-location'>
            <div style={{justifyContent: "space-between"}} className="horizontal">
                <h3 style={{margin: "0 0 10px 0"}}>{location.location_name}</h3>
                <p style={{fontSize: 8, margin: 0}}>({location.location_id})</p>
            </div>
            <div className='horizontal' style={{alignItems: "center", margin: "0 0 5px 0"}}>
                {location.location_address}
            </div>
            <div className="horizontal" style={{justifyContent: 'space-between'}}>
                <label style={{margin: 0}} className="container" htmlFor={`active${location.location_id}`}>{t('Active')}
                    <input id={`active${location.location_id}`} type="checkbox" defaultChecked={location.active_bool !== false} name="filter" onChange={(e) => {
                        const checked = e.target.checked;
                        const locId = location.location_id;
                        handleActiveChange(locId, checked);
                    }}/>
                    <span className="checkbox"></span>
                </label>
                <div className="horizontal hover" style={{alignItems: 'center'}} onClick={() => {
                    if (openLocationId === location.location_id) {
                        sendId(null);
                    } else {
                        sendId(location.location_id);
                    }
                }}>
                    <p style={{margin: '5px 0 5px 0'}}>{t('Opening_hours')}</p>
                    <img src={arrow} alt=">" className={`arrow-small rotatable ${openLocationId === location.location_id ? 'rotated-small' : ''}`}></img>
                </div>
            </div>
            {openLocationId === location.location_id &&
            <OpeningHours
            companyId={companyId}
            location={location}
            />}
        </div>
    )
}