
import { Route, Routes, Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import Update from "./components/Update.js";
import Articles from "./components/Articles.js";
import Article from "./components/Article.js";
import { useOrganization, useOrganizationList, OrganizationSwitcher, SignedIn, SignedOut, SignInButton, UserButton, useUser, useSession } from "@clerk/clerk-react";
import logo from "./images/logo.svg"
import Join from "./components/Join.js";
import langIcon from "./images/language-icon.svg";
import { useTranslation } from "react-i18next";
import PrivacyPolicy from "./components/PrivacyPolicy.js";
import Terms from "./components/Terms.js";
import Tutorial from "./components/Tutorial.js";
import Contact from "./components/Contact.js";
import Start from "./components/Start.js";
import NewLocation from "./components/NewLocation.js";

function App() {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState(null);
  const [organization, setOrganization] = useState(null);
  const [organizationId, setOrganizationId] = useState(null);
  const { isSignedIn, user } = useUser();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const headerRef = useRef(null);
  const menuRef = useRef(null);
  const { isLoaded, error } = useSession();
  const {i18n, t} = useTranslation();
  const [currentStep, setCurrentStep] = useState(0);
  const [tutorial, setTutorial] = useState(false);
  const org = useOrganization();
  const { setActive } = useOrganizationList({
    userMemberships: {
    infinite: true,
    },
  });
  const [orgHasBeenSet, setOrgHasBeenSet] = useState(false);

  useEffect(() => {
    const tutorial = localStorage.getItem('tutorial');
    const highlight = document.querySelector('.highlight');
    if (tutorial && tutorial === 'done') {
      if (highlight) highlight.style.boxShadow = '0 0 0 white';
      setTutorial(false);
    } else {
      if (highlight) highlight.style.boxShadow = '0 0 0 9999px rgba(0, 0, 0, 0.5)';
    }
  }, [])

  useEffect(() => {
    if (tutorial) {
      document.body.style.overflowY = 'hidden';
      document.documentElement.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'auto';
      document.documentElement.style.overflowY = 'auto';
    }
  }, [tutorial])

  const steps = [
    {
      element: null,
      description: t('welcome')
    },
    {
      element: '.currency',
      description: t('choose_market')
    },
    {
      element: '.bluePrice',
      description: t('bilberry_price')
    },
    {
      element: '.redPrice',
      description: t('lingonberry_price')
    },
    {
      element: '.hours-since',
      description: t('hours_since_updated_by_company')
    },
    {
      element: '.location',
      description: t('click_on_a_location_for_more_info')
    },
    {
      element: '.map',
      description: t('allow_geolocation_to_see_locations_near_you')
    }
  ];

  const nextStep = () => {
    setCurrentStep((prev) => (prev < steps.length - 1 ? prev + 1 : tutorialFinished()));
  }

  const prevStep = () => {
    setCurrentStep((prev) => (prev > 0 ? prev - 1 : prev));
  }

  function tutorialFinished() {
    localStorage.setItem("tutorial", 'done');
    const highlight = document.querySelector('.highlight');
    if (highlight) highlight.style.boxShadow = '0 0 0 white';
    setTutorial(false);
    window.location.reload();
  }

  function handleLanguageChange(lng) {
    i18n.changeLanguage(lng);
    localStorage.setItem('i18nextLng', lng);
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        headerRef.current && !headerRef.current.contains(event.target) &&
        menuRef.current && !menuRef.current.contains(event.target)
      ) {
        setTimeout(() => {
          setIsMenuOpen(false);
        }, 100)
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);
    
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (width > 768) setIsMenuOpen(false);
  }, [width])

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  async function setActiveOrg(id) {
    console.log(id);
    try {
      await setActive({organization: id});
      setOrgHasBeenSet(true);
    } catch (error) {
      console.error('Failed to set active organization:', error);
    }
  }

  useEffect(() => {
      if (isSignedIn) {
        setFirstName(user.firstName);
        if (user.organizationMemberships && user.organizationMemberships.length > 0 && org.isLoaded) {
          if (org.organization) {
            setOrganization(org.organization.name);
            setOrganizationId(org.organization.id);
          } else {
            setOrganization(user.organizationMemberships[0].organization.name);
            setOrganizationId(user.organizationMemberships[0].organization.id);
            if (!orgHasBeenSet) {
              setActiveOrg(user.organizationMemberships[0].organization.id);
            }
          }
        }
      }
      // eslint-disable-next-line
  }, [user, org])

  if (error) {
    console.log('Clerk error: ', error.message);
    return <div>{t('something_went_wrong')}...</div>
  }

  return (
    <>
      <header id="header" ref={headerRef} style={isMenuOpen ? null : {boxShadow: "5px 5px 5px rgba(0,0,0,0.1)"}}>
        <div style={{display: "flex", alignItems: "center"}}>
          <img src={logo} alt="logo" id="berryprice" onClick={() => {
            setIsMenuOpen(false);
            navigate(`/`);
            }}></img>
        </div>
        <div style={{display: "flex", alignItems: "center"}}>
          {!isMenuOpen ? (
            <>
              <img className={isSignedIn ? "widescreen" : "signed-out-small"} style={{height: "1.5em", margin: "0 10px 0 0"}} src={langIcon} alt=""></img>
              <select style={{margin: "0 40px 0 0"}} required className={isSignedIn ? "language widescreen" : "language"} name="language" id="language" value={i18n.language} onChange={(e) => handleLanguageChange(e.target.value)}>
                  <option value="en">English</option>
                  <option value="fi">Suomi</option>
                  <option value="se">Svenska</option>
              </select>
              <p
              onClick={() => {
                if (organizationId !== null) {
                  navigate(`/update/${organizationId}`)
                } else {
                  navigate('/join')
                }
              }}
              id="manageLocations"
              style={{margin: "0 40px 0 0"}}
              >
                {t('manage_locations')}
              </p>
              <SignedOut>
                <SignInButton>
                  <button>{t('sign_in')}</button>
                </SignInButton>
              </SignedOut>
              {!isLoaded ? (
                t('loading_user') + "..."
              ) : (
                null
              )}
              <SignedIn>
                <div id="user" style={{alignItems: "center"}}>
                  <p style={{margin: "0 5px 0 0"}}>{organization && firstName ?
                  firstName + " @ " : firstName ?
                  firstName :
                  ""}</p>
                  {
                  organization && user && user.organizationMemberships.length > 1 ?
                    <OrganizationSwitcher hidePersonal={true}/>
                  : organization ?
                    <p style={{marginRight: 10}}>{organization}</p>
                  : null
                  }
                  <UserButton afterSignOutUrl={'/'}/>
                </div>
              </SignedIn>
            </>
          ) : (
            <div></div>
          )}
            <SignedIn>
              <div className="hamburger-menu" onClick={toggleMenu}>☰</div>
            </SignedIn>
        </div>
      </header>
      {isMenuOpen ? (
        <div ref={menuRef} className="menu">
          <div style={{display: "flex", alignItems: "center", margin: "0 20px 10px 0"}}>
              <p style={{margin: "0 5px 0 0"}}>{organization && firstName ?
              firstName + " @" : firstName ?
              firstName :
              ""}</p>
              {
              organization && user && user.organizationMemberships.length > 1 ?
                <OrganizationSwitcher hidePersonal={true}/>
              : organization ?
                <p style={{marginRight: 10}}>{organization}</p>
              : null
              }
              <UserButton afterSignOutUrl={'/'}/>
          </div>
          <p
          onClick={(e) => {
            setIsMenuOpen(false);
            if (organizationId !== null) {
              navigate(`/update/${organizationId}`)
            } else {
              navigate('/join')
            }
          }}
          style={{margin: "0 20px 20px 0"}}
          >
            {t('manage_locations')}
          </p>
          <div style={{display: "flex", alignItems: "center", margin: "0 0 20px 0"}}>
            <img style={{height: "1.5em", margin: "0 10px 0 0"}} src={langIcon} alt=""></img>
            <select style={{margin: "0 20px 0px 0"}} required className='language' name="language" id="language" value={i18n.language} onChange={(e) => handleLanguageChange(e.target.value)}>
                <option value="en">English</option>
                <option value="fi">Suomi</option>
                <option value="se">Svenska</option>
            </select>
          </div>
        </div>
      ) : (
        <div></div>
      )}
      <div className="content">
        <Routes>
          <Route exact path="/" element={<Start orgId={organizationId}/>} />
          <Route exact path="/update" element={<Start orgId={organizationId}/>} />
          <Route exact path="/update/:id" element={<Update/>} />
          <Route exact path="/create-location/:id" element={<NewLocation/>} />
          <Route exact path="/create-location" element={<Start orgId={organizationId}/>} />
          <Route exact path="/articles" element={<Articles/>} />
          <Route exact path="/articles/:id" element={<Article/>} />
          <Route exact path="/join" element={<Join/>} />
          <Route exact path="/privacy" element={<PrivacyPolicy/>} />
          <Route exact path="/terms" element={<Terms/>} />
          <Route exact path="/contact" element={<Contact/>} />
        </Routes>
        {tutorial && <Tutorial steps={steps} currentStep={currentStep} nextStep={nextStep} prevStep={prevStep}></Tutorial>}
        <footer>
          <div className="footer-cont">
            <div className="privacy-terms">
              <Link to="/privacy">Privacy Policy</Link>
              <Link to="/terms">Terms and Conditions</Link>
            </div>
            <div className="contact-info">
              <div className="horizontal">
                <Link to="/contact" style={{marginRight: 5}}>{t('support')}</Link>
                |
                <Link to="/contact" style={{marginLeft: 5}}>{t('contact')}</Link>
              </div>
              {/* eslint-disable-next-line */}
              <a href="https://nostechnology.com" target="_blank">NOS Technology AB {new Date().getFullYear()}</a>
            </div>
          </div>
        </footer>
      </div>
    </>
  )
}

export default App;
