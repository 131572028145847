
import { useState } from "react";
import arrow from '../images/Vector 4.svg';
import directions from '../images/Group 4.svg';
import { t } from "i18next";

export default function Location({ company, calcDists, clientCoords }) {
    // States etc.
    const [openLocationId, setOpenLocationId] = useState(null);
    const [isHoursOpen, setIsHoursOpen] = useState(false);

    // Handle location click
    const handleLocationClick = (id) => {
        setIsHoursOpen(false);
        setOpenLocationId((prevId) => (prevId === id ? null : id));
    };

    return (
        <div style={{display: "flex", flexDirection: "column"}}>
        <div className="horizontal" style={{margin: '5px 0 5px 0'}}>
            <p style={{fontWeight: 600, margin: 0}}>{company.company_name} -</p>
            {/* eslint-disable-next-line */}
            {company.company_website && <a target="_blank" href={company.company_website.substring(0,7) === "http://" || company.company_website.substring(0,8) === "https://" ? company.company_website : `http://${company.company_website}`} style={{margin: '0 0 0 5px'}}>{company.company_website}</a>}
        </div>
        <div className="line" style={{margin: 0}}></div>
        {company.company_locations.map(location => (
            location.active_bool !== false && (
            <div key={location.location_id}>
            <div style={{justifyContent: 'flex-start', alignItems: 'center', overflow: 'hidden'}} className="horizontal location hidden-scrollbar" onClick={(e) => {
                e.preventDefault();
                handleLocationClick(location.location_id);
            }}>
                <p>{location.location_name}</p>
                <p style={{whiteSpace: "nowrap", margin: "0 0px 0 6px"}}>({calcDists(clientCoords.lat, clientCoords.lon, parseFloat(location.location_latitude), parseFloat(location.location_longitude))}km)</p>
                <img src={arrow} alt="arrow" className={`arrow-small rotatable ${openLocationId === location.location_id ? 'rotated-small' : ''}`}></img>
            </div>
            <div style={{display: 'flex', flexDirection: 'column'}}>
                {openLocationId === location.location_id ? (
                <>
                {/* eslint-disable-next-line */}
                <a target="_blank" href={`https://www.google.com/maps?q=${location.location_latitude},${location.location_longitude}`} style={{display: "flex", flexWrap: "wrap"}}>
                    <p style={{margin: "0 5px 0 0"}}>{location.location_address}</p>
                    <img src={directions} alt="directions" style={{height: "1em", width: "1em", margin: "0 0px 0 0"}}></img>
                </a>
                <a href={`mailto:${location.location_email}`}>{location.location_email}</a>
                <a href={`tel:${location.location_phone}`}>{location.location_phone}</a>
                {location.opening_hours && location.opening_hours.monday &&
                    <div style={{alignItems: 'center', justifyContent: 'flex-start', marginBottom: 10, height: 'auto'}} className="horizontal location" onClick={(e) => {
                        e.preventDefault();
                        setIsHoursOpen(!isHoursOpen);
                    }}>
                        <p style={{margin: 0}}>{t('Opening_hours')}</p>
                        <img src={arrow} alt="arrow" className={`arrow-small rotatable ${isHoursOpen ? 'rotated-small' : ''}`}></img>
                    </div>
                }
                {isHoursOpen ? (
                    <div style={{marginBottom: 10}}>
                        {location.opening_hours.monday.from_time || location.opening_hours.monday.to_time ? (
                            <div className="horizontal" style={{justifyContent: 'space-between'}}>
                                <p style={{margin: "0 10px 0 0"}}>{t('Monday')}</p>
                                <p style={{margin: 0}}>{location.opening_hours.monday.from_time} - {location.opening_hours.monday.to_time}</p>
                            </div>
                        ) : null}
                        {location.opening_hours.tuesday.from_time || location.opening_hours.tuesday.to_time ? (
                            <div className="horizontal" style={{justifyContent: 'space-between'}}>
                                <p style={{margin: "0 10px 0 0"}}>{t('Tuesday')}</p>
                                <p style={{margin: 0}}>{location.opening_hours.tuesday.from_time} - {location.opening_hours.tuesday.to_time}</p>
                            </div>
                        ) : null}
                        {location.opening_hours.wednesday.from_time || location.opening_hours.wednesday.to_time ? (
                            <div className="horizontal" style={{justifyContent: 'space-between'}}>
                                <p style={{margin: "0 10px 0 0"}}>{t('Wednesday')}</p>
                                <p style={{margin: 0}}>{location.opening_hours.wednesday.from_time} - {location.opening_hours.wednesday.to_time}</p>
                            </div>
                        ) : null}
                        {location.opening_hours.thursday.from_time || location.opening_hours.thursday.to_time ? (
                            <div className="horizontal" style={{justifyContent: 'space-between'}}>
                                <p style={{margin: "0 10px 0 0"}}>{t('Thursday')}</p>
                                <p style={{margin: 0}}>{location.opening_hours.thursday.from_time} - {location.opening_hours.thursday.to_time}</p>
                            </div>
                        ) : null}
                        {location.opening_hours.friday.from_time || location.opening_hours.friday.to_time ? (
                            <div className="horizontal" style={{justifyContent: 'space-between'}}>
                                <p style={{margin: "0 10px 0 0"}}>{t('Friday')}</p>
                                <p style={{margin: 0}}>{location.opening_hours.friday.from_time} - {location.opening_hours.friday.to_time}</p>
                            </div>
                        ) : null}
                        {location.opening_hours.saturday.from_time || location.opening_hours.saturday.to_time ? (
                            <div className="horizontal" style={{justifyContent: 'space-between'}}>
                                <p style={{margin: "0 10px 0 0"}}>{t('Saturday')}</p>
                                <p style={{margin: 0}}>{location.opening_hours.saturday.from_time} - {location.opening_hours.saturday.to_time}</p>
                            </div>
                        ) : null}
                        {location.opening_hours.sunday.from_time || location.opening_hours.sunday.to_time ? (
                            <div className="horizontal" style={{justifyContent: 'space-between'}}>
                                <p style={{margin: "0 10px 0 0"}}>{t('Sunday')}</p>
                                <p style={{margin: 0}}>{location.opening_hours.sunday.from_time} - {location.opening_hours.sunday.to_time}</p>
                            </div>
                        ) : null}
                        {!location.opening_hours.monday.from_time && !location.opening_hours.monday.to_time &&
                        !location.opening_hours.tuesday.from_time && !location.opening_hours.tuesday.to_time &&
                        !location.opening_hours.wednesday.from_time && !location.opening_hours.wednesday.to_time &&
                        !location.opening_hours.thursday.from_time && !location.opening_hours.thursday.to_time &&
                        !location.opening_hours.friday.from_time && !location.opening_hours.friday.to_time &&
                        !location.opening_hours.saturday.from_time && !location.opening_hours.saturday.to_time &&
                        !location.opening_hours.sunday.from_time && !location.opening_hours.sunday.to_time ? (
                            <p style={{margin: 0}}>{t('No_opening_hours_to_show')}</p>
                        ) : null}
                    </div>
                ) : null}
                </>
                ) : null}
            </div>
            <div className="line" style={{margin: 0}}></div>
            </div>
            )
        ))}
        </div>
    )
}