import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import translationSE from './locales/se/translation.json';
import translationEN from './locales/en/translation.json';
import translationFI from './locales/fi/translation.json';

const resources = {
    en: {
      translation: translationEN
    },
    se: {
      translation: translationSE
    },
    fi: {
      translation: translationFI
    }
};

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(Backend)
  .init({
    fallbackLng: 'en',
    resources,
    debug: 'true',
    detection: {
      order: ['queryString', 'cookie', 'localStorage', 'navigator', 'htmlTag'],
      caches: ['cookie']
    },
    backend: {
      loadPath: '/locales/{{lng}}/translation.json'
    },
    react: {
      useSuspense: false
    }
  });

export default i18n;
