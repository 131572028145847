
import { useEffect, useState } from 'react';
import arrow from '../images/Vector 4.svg';
import Location from './Location';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';

export default function Locations({companies, country, closestLocationDistances, calcDists, clientCoords, orgId}) {
    // States etc.
    const [openDropdownId, setOpenDropdownId] = useState(null);
    const navigate = useNavigate();
    const [organizationId, setOrganizationId] = useState(null);

    // Handle dropdown click
    const handleDropdownClick = (id) => {
        setOpenDropdownId((prevId) => (prevId === id ? null : id));
    };

    // Calc hours since date
    function calculateHoursSinceDate (dateString) {
        const pastDate = new Date(dateString + 'Z');
        const currentDate = new Date();
        const diffInMilliseconds = currentDate - pastDate;
        const diffInHours = Math.round(diffInMilliseconds / (1000 * 60 * 60));
        if (diffInHours > 72) return ('>72')
        return diffInHours;
    };

    // On orgId change
    useEffect(() => {
        setOrganizationId(orgId)
    }, [orgId])

    return (
        <>
        {companies.map(company => (
            <>
            <div key={company.company_id}>
                <div
                onClick={(e) => {
                    e.preventDefault();
                    handleDropdownClick(company.company_id);
                }}
                className="location"
                >
                <div className="hidden-scrollbar" style={{display: "flex", alignItems: "center", flexShrink: 0, overflow: 'hidden', marginRight: 5}}>
                    {company.berry_prices.country[country] && company.berry_prices.country[country].prices.bilberry > 0 ? (<div className="bluePrice" style={{display: "flex", alignItems: "center"}}><div style={{backgroundColor: "#3030ef", borderRadius: "50%", width: "0.8em", height: "0.8em", margin: "0 3px 0 0"}}></div><p style={{whiteSpace: "nowrap", margin: "0 6px 0 0"}}>{country === "Sweden" ? "" : "€"}{company.berry_prices.country[country].prices.bilberry}{country === "Sweden" ? "kr" : ""}</p></div>) : null}
                    {company.berry_prices.country[country] && company.berry_prices.country[country].prices.lingonberry > 0 ? (<div className="redPrice" style={{display: "flex", alignItems: "center"}}><div style={{backgroundColor: "#ef3030", borderRadius: "50%", width: "0.8em", height: "0.8em", margin: "0 3px 0 0"}}></div><p style={{whiteSpace: "nowrap", margin: "0 6px 0 0"}}>{country === "Sweden" ? "" : "€"}{company.berry_prices.country[country].prices.lingonberry}{country === "Sweden" ? "kr" : ""}</p></div>) : null}
                    {closestLocationDistances[company.company_id] && <p style={{whiteSpace: "nowrap", margin: "0 6px 0 0"}}>{closestLocationDistances[company.company_id] + " km"}</p>}
                    <p className="hours-since" style={{whiteSpace: "nowrap", margin: "0 0px 0 0"}}>({company.berry_prices.country[country] ? calculateHoursSinceDate(company.berry_prices.country[country].updated_info.update_timestamp) : "-"}h)</p>
                </div>
                <div style={{display: "flex", alignItems: "center", overflow: 'hidden'}}>
                    <p style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", textAlign: "right", margin: "0 0 0 0", width: '100%', minWidth: 0}}>{company.company_name}</p>
                    <img src={arrow} alt="arrow" className={`arrow-small rotatable ${openDropdownId === company.company_id ? 'rotated-small' : ''}`}></img>
                </div>
                </div>
            </div>
            <div>{openDropdownId === company.company_id && <Location company={company} calcDists={calcDists} clientCoords={clientCoords}/>}</div>
            </>
        ))}
        <div id="fake-padding"></div>
        <div className="bottom">
            <div style={{marginTop: 0}} className="line"></div>
            <h3 
            onClick={() => {
            if (organizationId !== null) {
                navigate(`/update/${organizationId}`)
            } else {
                navigate('/join')
            }
            }}
            className="no-button">
            + {t("add_location")}
            </h3>
        </div>
        </>
    )
}
