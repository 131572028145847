
import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { Helmet } from 'react-helmet-async';

export default function Article() {
    const {pathname} = useLocation();
    const id = pathname.substring(10);
    const [article, setArticle] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        async function fetchArticle() {
          try {
            const response = await fetch('/articles.json');
            const data = await response.json();
            setArticle(data[id]);
            setIsLoading(false);
          } catch (error) {
            console.error('Error fetching articles:', error);
            setIsLoading(false);
          }
        }
    
        fetchArticle();
    }, [id]);

    if (isLoading) {
        return (
            <div className="loading-container">
                <h1>Laddar...</h1>
            </div>
        )
    }

    return (
        <>
            <Helmet>
                <title>{article.title} - Berryprice</title>
                <meta name="description" content={article.description}/>
            </Helmet>
            <div className='article-container'>
                <div className='article'>
                    <h1>{article && article.title ? article.title : "Article not found"}</h1>
                    {article && article.content ? (
                    <ReactMarkdown
                        children={article.content}
                        components={{
                        a: ({ node, ...props }) => {
                            // If the link starts with http, render a normal <a> tag, otherwise use <Link>
                            if (props.href.startsWith('http')) {
                            // eslint-disable-next-line
                            return <a className='real-link' {...props} />;
                            } else {
                            return <Link className='real-link' to={props.href} {...props} />;
                            }
                        },
                        // eslint-disable-next-line
                        h1: ({node, ...props}) => <h1 style={{}} {...props} />,
                        // eslint-disable-next-line
                        h2: ({node, ...props}) => <h2 style={{}} {...props} />,
                        // eslint-disable-next-line
                        h3: ({node, ...props}) => <h3 style={{}} {...props} />,
                        ul: ({node, ...props}) => <ul style={{}} {...props} />,
                        ol: ({node, ...props}) => <ol style={{}} {...props} />,
                        li: ({node, ...props}) => <li style={{}} {...props} />
                        }}
                    />) : ""}
                </div>
            </div>
        </>
    )
}