
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useOrganization, RedirectToSignIn, useUser } from "@clerk/clerk-react";
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import arrow from '../images/Vector 4.svg';
import ManageLocation from './ManageLocation';

function Update() {

    const {pathname} = useLocation();
    const id = pathname.substring(8);
    const { isSignedIn, user } = useUser();
    const org = useOrganization();
    const [organization, setOrganization] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [locations, setLocations] = useState([]);
    const [primaryEmail, setPrimaryEmail] = useState(null);
    const [companyId, setCompanyId] = useState(null);
    const [members, setMembers] = useState([]);
    const navigate = useNavigate();
    const {t} = useTranslation();
    const [currency, setCurrency] = useState("EUR");
    const [allPrices, setAllPrices] = useState({
        bilberry: 0,
        lingonberry: 0
    });
    const [allSweden, setAllSweden] = useState({
        bilberry: 0,
        lingonberry: 0
    });
    const [allFinland, setAllFinland] = useState({
        bilberry: 0,
        lingonberry: 0
    });
    const [standardPrice, setStandardPrice] = useState(10);
    const [isAllError, setIsAllError] = useState(false);
    const [isAllSubmitting, setIsAllSubmitting] = useState(false);
    const [isAllSubmitted, setIsAllSubmitted] = useState(false);
    const [isFinlandError, setIsFinlandError] = useState(false);
    const [isFinlandSubmitting, setIsFinlandSubmitting] = useState(false);
    const [isFinlandSubmitted, setIsFinlandSubmitted] = useState(false);
    const [isSwedenError, setIsSwedenError] = useState(false);
    const [isSwedenSubmitting, setIsSwedenSubmitting] = useState(false);
    const [isSwedenSubmitted, setIsSwedenSubmitted] = useState(false);
    const [isMultinational, setIsMultinational] = useState(false);
    const [country, setCountry] = useState(null);
    const [firstSwedish, setFirstSwedish] = useState(null);
    const [firstFinnish, setFirstFinnish] = useState(null);
    const [isLocationsOpen, setIsLocationsOpen] = useState(false);
    const [unDefCountry, setUndefCountry] = useState(false);
    const [company, setCompany] = useState({});
    const [openLocationId, setOpenLocationId] = useState(null);

    const getId = (id) => {
        setOpenLocationId(id);
    }

    useEffect(() => {
        if (currency === "SEK") {
            setStandardPrice(10);
        } else {
            setStandardPrice(1);
        }
    }, [currency])

    useEffect(() => {
        if (isSignedIn) {
            setPrimaryEmail(user.primaryEmailAddress.emailAddress);
            if (user.organizationMemberships) {
                if (user.organizationMemberships.length > 0) {
                if (org.isLoaded) {
                    if (org.organization) {
                    setOrganization(org.organization);
                    } else {
                    setOrganization(user.organizationMemberships[0]);
                    }
                }
                }
            }
        }
        // eslint-disable-next-line
    }, [user, org.isLoaded])

    useEffect(() => {
        if (organization && organization.id !== id) {
            setIsAllError(false);
            setIsAllSubmitted(false);
            setIsAllSubmitting(false);
            setIsFinlandError(false);
            setIsFinlandSubmitted(false);
            setIsFinlandSubmitting(false);
            setIsSwedenError(false);
            setIsSwedenSubmitted(false);
            setIsSwedenSubmitting(false);
            setIsLoading(true);
            navigate(`/update/${organization.id}`)
        }
        //eslint-disable-next-line
    }, [organization])

    async function getMembers() {
        if (organization) {
            try {
                let memberships = await organization.getMemberships();
                const membersArr = [];
                memberships.data.forEach(member => {
                    let firstName = member.publicUserData.firstName ? member.publicUserData.firstName : "";
                    let lastName = member.publicUserData.lastName ? member.publicUserData.lastName : "";
                    let fullName = firstName + " " + lastName;
                    let name = fullName === "" ? member.publicUserData.identifier : fullName;
                    let role = member.role.substring(4);
                    membersArr.push({
                        name: name,
                        role: role
                    })
                });
                setMembers(membersArr);
            } catch (error) {
                console.log('error fetching members: ',error);
            }
        }
    }

    useEffect(() => {
        if (organization) {
            getMembers();

            fetch("https://bptestapi-e7db5f925e70.herokuapp.com/v4/companies/")
            .then(response => response.json())
            .then(data => {
                let locIds = [];
                let comId;
                for (let i = 0; i < data.length; i++) {
                    let companyName = data[i].company_name;
                    if (companyName === organization.name) {
                        locIds = data[i].company_locations.map(location => location.location_id);
                        comId = data[i].company_id;
                        setCompany(data[i]);
                        setCompanyId(comId);
                    }
                }
                fetchLocations(locIds, comId);
            })
            .catch(error => {
              console.log("Error fetching data: ", error);
              setIsLoading(true);
            })
        }
        // eslint-disable-next-line
    }, [organization])

    function handleLastIteration(fS, fF, multinational) {
        if (fS === null) {
            fS = {
                berry_prices: {
                    bilberry: 0,
                    lingonberry: 0
                }
            };
            setFirstSwedish({
                berry_prices: {
                    bilberry: 0,
                    lingonberry: 0
                }
            });
        }
        if (fF === null) {
            fF = {
                berry_prices: {
                    bilberry: 0,
                    lingonberry: 0
                }
            };
            setFirstFinnish({
                berry_prices: {
                    bilberry: 0,
                    lingonberry: 0
                }
            });
        }
        if (!multinational) setIsMultinational(false);
    }

    async function fetchLocations(locIds, comId) {
        if (locIds && locIds.length > 0) {
            setLocations([]);
            let multinational = false;
            let allPricesSet = false;  // Initialize flag to track if allPrices is set
            let undefCountrySet = false;  // Initialize flag to track if undefCountry is set
            let countrySet = false;  // Initialize flag to track if country is set
            let currencySet = false;  // Initialize flag to track if currency is set
            let fF = null;
            let fS = null;
            let nation = null;
    
            await locIds.forEach((locId, index) => {
                (function (locId) {  // IIFE to create a new scope
                    fetch(`https://bptestapi-e7db5f925e70.herokuapp.com/v4/locations/${locId}`)
                    .then(response => response.json())
                    .then(data => {
                        if (data.company_id === comId) {
                            if (!countrySet) {
                                setCountry(data.location_country);
                                countrySet = true;
                            }
    
                            if (!currencySet) {
                                setCurrency(data.location_currency);
                                currencySet = true;
                            }
    
                            if (!allPricesSet) {
                                setAllPrices(data.berry_prices.country[data.location_country].prices);
                                allPricesSet = true;
                            }
    
                            if (!multinational && nation && nation !== data.location_country) {
                                setIsMultinational(true);
                                multinational = true;
                            }

                            nation = data.location_country;
    
                            if (data.location_country !== "Sweden" && data.location_country !== "Finland" && !undefCountrySet) {
                                setUndefCountry(true);
                                undefCountrySet = true;
                            }
    
                            setLocations((prevArray) => [...prevArray, data]);
    
                            if (data.location_country === "Finland" && data.location_currency === "EUR") {
                                if (fF === null) {
                                    fF = data;
                                    setFirstFinnish(data);
                                    setAllFinland(data.berry_prices.country.Finland.prices);
                                }
                            }
    
                            if (data.location_country === "Sweden" && data.location_currency === "SEK") {
                                if (fS === null) {
                                    fS = data;
                                    setFirstSwedish(data);
                                    setAllSweden(data.berry_prices.country.Sweden.prices);
                                }
                            }

                            if (index === locIds.length - 1) handleLastIteration(fS, fF, multinational);
                        }
                    })
                    .catch(error => {
                        console.log("Error fetching data: ", error);
                        setIsLoading(true);
                    });
                })(locId);
            });
            setIsLoading(false);
        }
    }

    function updateAllStatus(nation, type, status, trueFalse) {
        if (trueFalse) {
            if (status === "Submitting") {
                if (type === "all") setIsAllSubmitting(true);
                if (type === "partial") {
                    if (nation === "Finland") setIsFinlandSubmitting(true);
                    if (nation === "Sweden") setIsSwedenSubmitting(true);
                }
            }
            if (status === "Error") {
                if (type === "all") setIsAllError(true);
                if (type === "partial") {
                    if (nation === "Finland") setIsFinlandError(true);
                    if (nation === "Sweden") setIsSwedenError(true);
                }
            }
            if (status === "Submitted") {
                if (type === "all") setIsAllSubmitted(true);
                if (type === "partial") {
                    if (nation === "Finland") setIsFinlandSubmitted(true);
                    if (nation === "Sweden") setIsSwedenSubmitted(true);
                }
            }
        } else {
            if (status === "Submitting") {
                if (type === "all") setIsAllSubmitting(false);
                if (type === "partial") {
                    if (nation === "Finland") setIsFinlandSubmitting(false);
                    if (nation === "Sweden") setIsSwedenSubmitting(false);
                }
            }
            if (status === "Error") {
                if (type === "all") setIsAllError(false);
                if (type === "partial") {
                    if (nation === "Finland") setIsFinlandError(false);
                    if (nation === "Sweden") setIsSwedenError(false);
                }
            }
            if (status === "Submitted") {
                if (type === "all") setIsAllSubmitted(false);
                if (type === "partial") {
                    if (nation === "Finland") setIsFinlandSubmitted(false);
                    if (nation === "Sweden") setIsSwedenSubmitted(false);
                }
            }
        }
    }

    async function handleUpdateAll(nation, type) {
        updateAllStatus(nation, type, "Submitting", true);
        
        const data = {
            company_id: companyId,
            berry_prices: {
                country: {
                    [nation]: {
                        currency: nation === "Sweden" ? "SEK" : "EUR",
                        prices: type === "all" ? allPrices : nation === "Sweden" ? allSweden : allFinland,
                        updated_info: {
                            updated_by: primaryEmail
                        }
                    }
                }
            }
        }
        const url = "https://bptestapi-e7db5f925e70.herokuapp.com/v4/price_updates/company/";

        try {
            const response = await fetch(url,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
            if (!response.ok) {
                updateAllStatus(nation, type, "Submitting", false);
                updateAllStatus(nation, type, "Error", true);
                console.log('http error: ', response);
            } else {
                updateAllStatus(nation, type, "Submitting", false);
                updateAllStatus(nation, type, "Submitted", true);
            }
        } catch (error) {
            updateAllStatus(nation, type, "Submitting", false);
            updateAllStatus(nation, type, "Error", true);
            console.log('error fetching: ', error);
        }
    }

    const handleLocationsClick = () => {
        setIsLocationsOpen(!isLocationsOpen);
    };

    if (isLoading) {
        return (
            <div className="loading-container">
                <h1>{t('loading')}...</h1>
            </div>
        )
    }

    return (
        <div className='update'>
            <Helmet>
                <title>{organization ? `${organization.name}` : "Företag"} - Berryprice</title>
            </Helmet>
            {organization && id === organization.id ? (
                <>
                <h1>{organization.name}</h1>
                <div className="card-cont">
                    <div className="card" id="manageLocsCard">
                        <div style={{display: 'flex', flexWrap: 'wrap', alignItems: 'baseline', justifyContent: 'space-between'}}>
                            <h2 style={{margin: "5px 10px 5px 0"}}>{t('manage_locations')}</h2>
                            <button onClick={() => navigate(`/create-location/${organization.id}`)} style={{margin: "5px 0 5px 0"}}>+ {t("add_location")}</button>
                        </div>
                        {locations && locations.length > 0 && isMultinational !== null ? (
                            <>
                            <div className='line' style={{marginBottom: 20}}></div>
                            {!isMultinational ? (
                                <>
                                <div style={{width: '100%', maxWidth: '300px', padding: 15, boxSizing: 'border-box', border: '0.5px solid rgba(0,0,0,0.3)', borderRadius: 10, margin: '0px 0px 0 0'}}>
                                <div className='horizontal'>
                                    <h3 style={{margin: '0 0 10px'}}>{t('update_all_locations')}</h3>
                                </div>
                                <p>{organization.name}</p>
                                <form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    handleUpdateAll(country, "all");
                                }}
                                >
                                <div>
                                    <div style={{justifyContent: 'space-between', margin: '0 0 5px 0'}} className='horizontal'>
                                        <label style={{margin: 0}} className="container" htmlFor="allBlueberries">{t('bilberry')+": "}
                                            <input id="allBlueberries" type="checkbox" defaultChecked={company.berry_prices && company.berry_prices.country[country].prices.bilberry > 0} name="filter" onChange={(e) => {
                                                // handle check for blueberries
                                                const checked = e.target.checked;
                                                const element = document.getElementById('allBlueberriesPrice');
                                                if (!checked) {
                                                    setAllPrices((prevPrices) => ({
                                                        ...prevPrices,
                                                        bilberry: 0
                                                    }));
                                                    element.value = 0;
                                                } else {
                                                    setAllPrices((prevPrices) => ({
                                                        ...prevPrices,
                                                        bilberry: standardPrice
                                                    }));
                                                    element.value = standardPrice;
                                                }
                                            }}/>
                                            <span className="checkbox"></span>
                                        </label>
                                        <div className="horizontal">
                                            <label>
                                            <input
                                                id="allBlueberriesPrice"
                                                type="number"
                                                step="any"
                                                style={{width: 50}}
                                                min={0}
                                                max={99}
                                                defaultValue={company.berry_prices ? company.berry_prices.country[country].prices.bilberry : 0}
                                                onChange={(e) => {
                                                    // handle price change
                                                    setAllPrices((prevPrices) => ({
                                                        ...prevPrices,
                                                        bilberry: parseFloat(e.target.value)
                                                    }));
                                                    const element = document.getElementById('allBlueberries');
                                                    if (e.target.value > 0) {
                                                        element.checked = true;
                                                    } else {
                                                        element.checked = false;
                                                    }
                                                }}
                                            />
                                            {currency === "EUR" ? (" €/kg") : (" kr/kg")}
                                            </label>
                                        </div>
                                    </div>
                                    <div style={{justifyContent: 'space-between', margin: '0 0 5px 0'}} className='horizontal'>
                                        <label style={{margin: 0}} className="container" htmlFor={"allLingonberries"}>{t('lingonberry') + ": "}
                                            <input id="allLingonberries" type="checkbox" defaultChecked={company.berry_prices && company.berry_prices.country[country].prices.lingonberry > 0} name="filter" onChange={(e) => {
                                                // handle check for lingonberries
                                                const checked = e.target.checked;
                                                const element = document.getElementById('allLingonberriesPrice');
                                                if (!checked) {
                                                    setAllPrices((prevPrices) => ({
                                                        ...prevPrices,
                                                        lingonberry: 0
                                                    }));
                                                    element.value = 0;
                                                } else {
                                                    setAllPrices((prevPrices) => ({
                                                        ...prevPrices,
                                                        lingonberry: standardPrice
                                                    }));
                                                    element.value = standardPrice;
                                                }
                                            }}/>
                                            <span className="checkbox"></span>
                                        </label>
                                        <div className="horizontal">
                                            <label>
                                            <input
                                                id="allLingonberriesPrice"
                                                type="number"
                                                step="any"
                                                style={{width: 50}}
                                                min={0}
                                                max={99}
                                                defaultValue={company.berry_prices ? company.berry_prices.country[country].prices.lingonberry : 0}
                                                onChange={(e) => {
                                                    // handle price change
                                                    setAllPrices((prevPrices) => ({
                                                        ...prevPrices,
                                                        lingonberry: parseFloat(e.target.value)
                                                    }));
                                                    const element = document.getElementById('allLingonberries');
                                                    if (e.target.value > 0) {
                                                        element.checked = true;
                                                    } else {
                                                        element.checked = false;
                                                    }
                                                }}
                                            />
                                            {currency === "EUR" ? (" €/kg") : (" kr/kg")}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className='horizontal' style={{ justifyContent: 'space-between' }}>
                                    <button style={{ margin: "15px 0 0 0" }} type="submit">{t('confirm')}</button>
                                    {isAllError ? (
                                        <p style={{ color: "#ef3030" }}>{t('something_went_wrong')}</p>
                                    ) : isAllSubmitting ? (
                                        <p>{t('updating')}...</p>
                                    ) : isAllSubmitted ? (
                                        <p style={{ color: "#16d316" }}>{t('prices_updated')}!</p>
                                    ) : (
                                        null
                                    )}
                                </div>
                                </form>
                                </div>
                                </>
                            ) : firstFinnish && firstSwedish ? (
                                <div className='update-cont'>
                                <div style={{width: '100%', maxWidth: '300px', padding: 15, boxSizing: 'border-box', border: '0.5px solid rgba(0,0,0,0.3)', borderRadius: 10, margin: '0px 0px 0 0'}}>
                                <div className='horizontal' style={{alignItems: "center"}}>
                                    <h3 style={{margin: '0 0 0px'}}>{t('update') + " " + t('sweden')}</h3>
                                    <img alt="SE" style={{margin: "0 0px 0 5px", height: "16px"}} src="https://flagsapi.com/SE/flat/64.png"/>
                                </div>
                                <p>{organization.name + " " + t('sweden')}</p>
                                <form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    handleUpdateAll("Sweden", "partial");
                                }}
                                >
                                <div>
                                    <div style={{justifyContent: 'space-between', margin: '0 0 5px 0'}} className='horizontal'>
                                        <label style={{margin: 0}} className="container" htmlFor="swedenBlueberries">{t('bilberry')+": "}
                                            <input id="swedenBlueberries" type="checkbox" defaultChecked={company.berry_prices.country.Sweden && company.berry_prices.country.Sweden.prices && company.berry_prices.country.Sweden.prices.bilberry > 0} name="filter" onChange={(e) => {
                                                // handle check for blueberries
                                                const checked = e.target.checked;
                                                const element = document.getElementById('swedenBlueberriesPrice');
                                                if (!checked) {
                                                    setAllSweden((prevPrices) => ({
                                                        ...prevPrices,
                                                        bilberry: 0
                                                    }));
                                                    element.value = 0;
                                                } else {
                                                    setAllSweden((prevPrices) => ({
                                                        ...prevPrices,
                                                        bilberry: 10
                                                    }));
                                                    element.value = 10;
                                                }
                                            }}/>
                                            <span className="checkbox"></span>
                                        </label>
                                        <div className="horizontal">
                                            <label>
                                            <input
                                                id="swedenBlueberriesPrice"
                                                type="number"
                                                step="any"
                                                style={{width: 50, marginRight: 5}}
                                                min={0}
                                                max={99}
                                                defaultValue={company.berry_prices.country.Sweden && company.berry_prices.country.Sweden.prices ? company.berry_prices.country.Sweden.prices.bilberry : 0}
                                                onChange={(e) => {
                                                    // handle price change
                                                    setAllSweden((prevPrices) => ({
                                                        ...prevPrices,
                                                        bilberry: parseFloat(e.target.value)
                                                    }));
                                                    const element = document.getElementById('swedenBlueberries');
                                                    if (e.target.value > 0) {
                                                        element.checked = true;
                                                    } else {
                                                        element.checked = false;
                                                    }
                                                }}
                                            />
                                            {"kr/kg"}
                                            </label>
                                        </div>
                                    </div>
                                    <div style={{justifyContent: 'space-between', margin: '0 0 5px 0'}} className='horizontal'>
                                        <label style={{margin: 0}} className="container" htmlFor={"swedenLingonberries"}>{t('lingonberry') + ": "}
                                            <input id="swedenLingonberries" type="checkbox" defaultChecked={company.berry_prices.country.Sweden && company.berry_prices.country.Sweden.prices && company.berry_prices.country.Sweden.prices.lingonberry > 0} name="filter" onChange={(e) => {
                                                // handle check for lingonberries
                                                const checked = e.target.checked;
                                                const element = document.getElementById('swedenLingonberriesPrice');
                                                if (!checked) {
                                                    setAllSweden((prevPrices) => ({
                                                        ...prevPrices,
                                                        lingonberry: 0
                                                    }));
                                                    element.value = 0;
                                                } else {
                                                    setAllSweden((prevPrices) => ({
                                                        ...prevPrices,
                                                        lingonberry: 10
                                                    }));
                                                    element.value = 10;
                                                }
                                            }}/>
                                            <span className="checkbox"></span>
                                        </label>
                                        <div className="horizontal">
                                            <label>
                                            <input
                                                id="swedenLingonberriesPrice"
                                                type="number"
                                                step="any"
                                                style={{width: 50, marginRight: 5}}
                                                min={0}
                                                max={99}
                                                defaultValue={company.berry_prices.country.Sweden && company.berry_prices.country.Sweden.prices ? company.berry_prices.country.Sweden.prices.lingonberry : 0}
                                                onChange={(e) => {
                                                    // handle price change
                                                    setAllSweden((prevPrices) => ({
                                                        ...prevPrices,
                                                        lingonberry: parseFloat(e.target.value)
                                                    }));
                                                    const element = document.getElementById('swedenLingonberries');
                                                    if (e.target.value > 0) {
                                                        element.checked = true;
                                                    } else {
                                                        element.checked = false;
                                                    }
                                                }}
                                            />
                                            {"kr/kg"}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className='horizontal' style={{ justifyContent: 'space-between' }}>
                                    <button style={{ margin: "15px 0 0 0" }} type="submit">{t('confirm')}</button>
                                    {isSwedenError ? (
                                        <p style={{ color: "#ef3030" }}>{t('something_went_wrong')}</p>
                                    ) : isSwedenSubmitting ? (
                                        <p>{t('updating')}...</p>
                                    ) : isSwedenSubmitted ? (
                                        <p style={{ color: "#16d316" }}>{t('prices_updated')}!</p>
                                    ) : (
                                        null
                                    )}
                                </div>
                                </form>
                                </div>
                                <div style={{width: '100%', maxWidth: '300px', padding: 15, boxSizing: 'border-box', border: '0.5px solid rgba(0,0,0,0.3)', borderRadius: 10, margin: '0px 0px 0 0'}}>
                                <div className='horizontal' style={{alignItems: "center"}}>
                                    <h3 style={{margin: '0 0 0px'}}>{t('update') + " " + t('finland')}</h3>
                                    <img alt="FI" style={{margin: "0 0px 0 5px", height: "16px"}} src="https://flagsapi.com/FI/flat/64.png"/>
                                </div>
                                <p>{organization.name + " " + t('finland')}</p>
                                <form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    handleUpdateAll("Finland", "partial");
                                }}
                                >
                                <div>
                                    <div style={{justifyContent: 'space-between', margin: '0 0 5px 0'}} className='horizontal'>
                                        <label style={{margin: 0}} className="container" htmlFor="finlandBlueberries">{t('bilberry')+": "}
                                            <input id="finlandBlueberries" type="checkbox" defaultChecked={company.berry_prices.country.Finland && company.berry_prices.country.Finland.prices && company.berry_prices.country.Finland.prices.bilberry > 0} name="filter" onChange={(e) => {
                                                // handle check for blueberries
                                                const checked = e.target.checked;
                                                const element = document.getElementById('finlandBlueberriesPrice');
                                                if (!checked) {
                                                    setAllFinland((prevPrices) => ({
                                                        ...prevPrices,
                                                        bilberry: 0
                                                    }));
                                                    element.value = 0;
                                                } else {
                                                    setAllFinland((prevPrices) => ({
                                                        ...prevPrices,
                                                        bilberry: 1
                                                    }));
                                                    element.value = 1;
                                                }
                                            }}/>
                                            <span className="checkbox"></span>
                                        </label>
                                        <div className="horizontal">
                                            <label>
                                            <input
                                                id="finlandBlueberriesPrice"
                                                type="number"
                                                step="any"
                                                style={{width: 50, marginRight: 5}}
                                                min={0}
                                                max={99}
                                                defaultValue={company.berry_prices.country.Finland && company.berry_prices.country.Finland.prices ? company.berry_prices.country.Finland.prices.bilberry : 0}
                                                onChange={(e) => {
                                                    // handle price change
                                                    setAllFinland((prevPrices) => ({
                                                        ...prevPrices,
                                                        bilberry: parseFloat(e.target.value)
                                                    }));
                                                    const element = document.getElementById('finlandBlueberries');
                                                    if (e.target.value > 0) {
                                                        element.checked = true;
                                                    } else {
                                                        element.checked = false;
                                                    }
                                                }}
                                            />
                                            {"€/kg"}
                                            </label>
                                        </div>
                                    </div>
                                    <div style={{justifyContent: 'space-between', margin: '0 0 5px 0'}} className='horizontal'>
                                        <label style={{margin: 0}} className="container" htmlFor={"finlandLingonberries"}>{t('lingonberry') + ": "}
                                            <input id="finlandLingonberries" type="checkbox" defaultChecked={company.berry_prices.country.Finland && company.berry_prices.country.Finland.prices && company.berry_prices.country.Finland.prices.lingonberry > 0} name="filter" onChange={(e) => {
                                                // handle check for lingonberries
                                                const checked = e.target.checked;
                                                const element = document.getElementById('finlandLingonberriesPrice');
                                                if (!checked) {
                                                    setAllFinland((prevPrices) => ({
                                                        ...prevPrices,
                                                        lingonberry: 0
                                                    }));
                                                    element.value = 0;
                                                } else {
                                                    setAllFinland((prevPrices) => ({
                                                        ...prevPrices,
                                                        lingonberry: 1
                                                    }));
                                                    element.value = 1;
                                                }
                                            }}/>
                                            <span className="checkbox"></span>
                                        </label>
                                        <div className="horizontal">
                                            <label>
                                            <input
                                                id="finlandLingonberriesPrice"
                                                type="number"
                                                step="any"
                                                style={{width: 50, marginRight: 5}}
                                                min={0}
                                                max={99}
                                                defaultValue={company.berry_prices.country.Finland && company.berry_prices.country.Finland.prices ? company.berry_prices.country.Finland.prices.lingonberry : 0}
                                                onChange={(e) => {
                                                    // handle price change
                                                    setAllFinland((prevPrices) => ({
                                                        ...prevPrices,
                                                        lingonberry: parseFloat(e.target.value)
                                                    }));
                                                    const element = document.getElementById('finlandLingonberries');
                                                    if (e.target.value > 0) {
                                                        element.checked = true;
                                                    } else {
                                                        element.checked = false;
                                                    }
                                                }}
                                            />
                                            {"€/kg"}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className='horizontal' style={{ justifyContent: 'space-between' }}>
                                    <button style={{ margin: "15px 0 0 0" }} type="submit">{t('confirm')}</button>
                                    {isFinlandError ? (
                                        <p style={{ color: "#ef3030" }}>{t('something_went_wrong')}</p>
                                    ) : isFinlandSubmitting ? (
                                        <p>{t('updating')}...</p>
                                    ) : isFinlandSubmitted ? (
                                        <p style={{ color: "#16d316" }}>{t('prices_updated')}!</p>
                                    ) : (
                                        null
                                    )}
                                </div>
                                </form>
                                </div>
                                </div>
                            ) : null }
                            </>
                        ) : (
                            null
                        )}
                        <div style={{backgroundColor: "rgba(0,0,0,0.2)", height: 0.5, margin: "20px 0 10px 0"}}></div>
                        <div className='filter-title' style={{justifyContent: "flex-start", margin: 0}} onClick={handleLocationsClick}>
                            <h3>{t('locations')}</h3>
                            <img src={arrow} alt="arrow" className={`arrow rotatable ${isLocationsOpen ? 'rotated' : ''}`}></img>
                        </div>
                        {isLocationsOpen ? (
                            isMultinational ? (
                                <>
                                <div className='horizontal' style={{alignItems: "center"}}>
                                    <h3>{t('sweden')}</h3>
                                    <img alt="SE" style={{margin: "0 0 0 5px", height: "16px"}} src="https://flagsapi.com/SE/flat/64.png"/>
                                </div>
                                <div className={`update-cont ${locations.length === 1 ? 'single-child' : ''}`}>
                                    {locations && locations.length > 0 ? locations.map((location, index) => (
                                        location.location_country === "Sweden" ? (
                                            <ManageLocation
                                            location={location}
                                            companyId={companyId}
                                            openLocationId={openLocationId}
                                            sendId={getId}
                                            />
                                        ) : (
                                            null
                                        )
                                    )) : <h3>{t('no_locations_found')}</h3>}
                                </div>
                                <div className='line' style={{marginTop: 20}}></div>
                                <div className='horizontal' style={{alignItems: "center"}}>
                                    <h3>{t('finland')}</h3>
                                    <img alt="FI" style={{margin: "0 0px 0 5px", height: "16px"}} src="https://flagsapi.com/FI/flat/64.png"/>
                                </div>
                                <div className={`update-cont ${locations.length === 1 ? 'single-child' : ''}`}>
                                    {locations && locations.length > 0 ? locations.map((location, index) => (
                                        location.location_country === "Finland" ? (
                                            <ManageLocation
                                            openLocationId={openLocationId}
                                            location={location}
                                            companyId={companyId}
                                            sendId={getId}
                                            />
                                        ) : (
                                            null
                                        )
                                    )) : <h3>{t('no_locations_found')}</h3>}
                                </div>
                                {unDefCountry ? (
                                    <>
                                    <div className='line' style={{marginTop: 20}}></div>
                                    <h3>{t('other')}</h3>
                                    <div className={`update-cont ${locations.length === 1 ? 'single-child' : ''}`}>
                                        {locations && locations.length > 0 ? locations.map((location, index) => (
                                            location.location_country !== "Sweden" && location.location_country !== "Finland" ? (
                                                <ManageLocation
                                                openLocationId={openLocationId}
                                                location={location}
                                                companyId={companyId}
                                                sendId={getId}
                                                />
                                            ) : (
                                                null
                                            )
                                        )) : <h3>{t('no_locations_found')}</h3>}
                                    </div>
                                    </>
                                ) : null}
                                </>
                            ) : (
                                <div className={`update-cont ${locations.length === 1 ? 'single-child' : ''}`}>
                                    {locations && locations.length > 0 ? locations.map((location, index) => (
                                        <ManageLocation
                                        openLocationId={openLocationId}
                                        location={location}
                                        companyId={companyId}
                                        sendId={getId}
                                        />
                                    )) : <h3>{t('no_locations_found')}</h3>}
                                </div>
                            )
                        ) : null}
                    </div>
                    <div className="card" id='membersCard'>
                        <h2>{t('members')}</h2>
                        <div className='line'></div>
                        {members && members.length > 0 ? (
                            <>
                            {members.map((member, index) => (
                                <div key={index} className='horizontal'>
                                    <p style={{width: "100%"}} key={`name${index}`}>{member.name}</p>
                                </div>
                            ))}
                            </>
                        ) : (
                            <p>{t('no_members_found')}</p>
                        )}
                    </div>
                </div>
                </>
            ) : !isSignedIn ? (
                <RedirectToSignIn/>
            ) : (
                <h3>{t('access_denied')}</h3>
            )}
        </div>
    )
}

export default Update
