
import { useEffect, useState } from "react";
import { t } from "i18next";

export default function OpeningHours({companyId, location}) {
    const [openingHours, setOpeningHours] = useState(location.opening_hours);
    const [isError, setIsError] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    useEffect(() => {
        const weekDays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
        if (!openingHours) {
            setOpeningHours({
                monday: {
                    from_time: "",
                    to_time: ""
                },
                tuesday: {
                    from_time: "",
                    to_time: ""
                },
                wednesday: {
                    from_time: "",
                    to_time: ""
                },
                thursday: {
                    from_time: "",
                    to_time: ""
                },
                friday: {
                    from_time: "",
                    to_time: ""
                },
                saturday: {
                    from_time: "",
                    to_time: ""
                },
                sunday: {
                    from_time: "",
                    to_time: ""
                }
            })
        } else {
            weekDays.forEach(day => {
                if (!openingHours[day]) {
                    setOpeningHours(prevState => ({
                        ...prevState,
                    [day]: {
                            ...prevState[day],
                            to_time: "",
                            from_time: ""
                        }
                    }))
                } else if (!openingHours[day].from_time || !openingHours[day].to_time) {
                    setOpeningHours(prevState => ({
                        ...prevState,
                    [day]: {
                            ...prevState[day],
                            to_time: "",
                            from_time: ""
                        }
                    }))
                }
            })
        }
        // eslint-disable-next-line
    }, [])

    const handleHoursChange = (day, field, value) => {
        setOpeningHours(prevState => ({
            ...prevState,
            [day]: {
                ...prevState[day],
                [field]: value
            }
        }));
    };

    async function handleSubmit(e) {
        e.preventDefault();
        setIsSubmitting(true);
        setIsError(false);
        setIsSubmitted(false);

        const data = {
            company_id: companyId,
            location_id: location.location_id,
            opening_hours: openingHours
        }
        const url = "https://bptestapi-e7db5f925e70.herokuapp.com/v4/location_update/";

        try {
            const response = await fetch(url,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
            if (!response.ok) {
                setIsSubmitting(false);
                setIsError(true);
                setIsSubmitted(false);
                console.log('http error: ', response);
            } else {
                setIsSubmitting(false);
                setIsError(false);
                setIsSubmitted(true);
            }
        } catch (error) {
            setIsSubmitting(false);
            setIsError(true);
            setIsSubmitted(false);
            console.log('error (de)activating: ', error);
        }
    }

    if (openingHours && openingHours.monday && openingHours.monday.from_time !== null) return (
        <form style={{margin: '10px 0 0 0'}} onSubmit={(e) => handleSubmit(e)}>
        <div className='horizontal time' style={{justifyContent: 'space-between'}}>
            <p style={{margin: 0}}>Mon</p>
            <div className='horizontal time-cont'>
                <input type='time' className='create-input' style={{margin: 0}} max={openingHours.monday.to_time} value={openingHours.monday.from_time} onChange={(e) => handleHoursChange('monday', 'from_time', e.target.value)}></input>
                <p style={{margin: '0 5px'}}>-</p>
                <input type='time' className='create-input' style={{margin: 0}} min={openingHours.monday.from_time} value={openingHours.monday.to_time} onChange={(e) => handleHoursChange('monday', 'to_time', e.target.value)}></input>
            </div>
        </div>
        <div className='horizontal time' style={{justifyContent: 'space-between'}}>
            <p style={{margin: 0}}>Tue</p>
            <div className='horizontal time-cont'>
                <input type='time' className='create-input' style={{margin: 0}} max={openingHours.tuesday.to_time} value={openingHours.tuesday.from_time} onChange={(e) => handleHoursChange('tuesday', 'from_time', e.target.value)}></input>
                <p style={{margin: '0 5px'}}>-</p>
                <input type='time' className='create-input' style={{margin: 0}} min={openingHours.tuesday.from_time} value={openingHours.tuesday.to_time} onChange={(e) => handleHoursChange('tuesday', 'to_time', e.target.value)}></input>
            </div>
        </div>
        <div className='horizontal time' style={{justifyContent: 'space-between'}}>
            <p style={{margin: 0}}>Wed</p>
            <div className='horizontal time-cont'>
                <input type='time' className='create-input' style={{margin: 0}} max={openingHours.wednesday.to_time} value={openingHours.wednesday.from_time} onChange={(e) => handleHoursChange('wednesday', 'from_time', e.target.value)}></input>
                <p style={{margin: '0 5px'}}>-</p>
                <input type='time' className='create-input' style={{margin: 0}} min={openingHours.wednesday.from_time} value={openingHours.wednesday.to_time} onChange={(e) => handleHoursChange('wednesday', 'to_time', e.target.value)}></input>
            </div>
        </div>
        <div className='horizontal time' style={{justifyContent: 'space-between'}}>
            <p style={{margin: 0}}>Thu</p>
            <div className='horizontal time-cont'>
                <input type='time' className='create-input' style={{margin: 0}} max={openingHours.thursday.to_time} value={openingHours.thursday.from_time} onChange={(e) => handleHoursChange('thursday', 'from_time', e.target.value)}></input>
                <p style={{margin: '0 5px'}}>-</p>
                <input type='time' className='create-input' style={{margin: 0}} min={openingHours.thursday.from_time} value={openingHours.thursday.to_time} onChange={(e) => handleHoursChange('thursday', 'to_time', e.target.value)}></input>
            </div>
        </div>
        <div className='horizontal time' style={{justifyContent: 'space-between'}}>
            <p style={{margin: 0}}>Fri</p>
            <div className='horizontal time-cont'>
                <input type='time' className='create-input' style={{margin: 0}} max={openingHours.friday.to_time} value={openingHours.friday.from_time} onChange={(e) => handleHoursChange('friday', 'from_time', e.target.value)}></input>
                <p style={{margin: '0 5px'}}>-</p>
                <input type='time' className='create-input' style={{margin: 0}} min={openingHours.friday.from_time} value={openingHours.friday.to_time} onChange={(e) => handleHoursChange('friday', 'to_time', e.target.value)}></input>
            </div>
        </div>
        <div className='horizontal time' style={{justifyContent: 'space-between'}}>
            <p style={{margin: 0}}>Sat</p>
            <div className='horizontal time-cont'>
                <input type='time' className='create-input' style={{margin: 0}} max={openingHours.saturday.to_time} value={openingHours.saturday.from_time} onChange={(e) => handleHoursChange('saturday', 'from_time', e.target.value)}></input>
                <p style={{margin: '0 5px'}}>-</p>
                <input type='time' className='create-input' style={{margin: 0}} min={openingHours.saturday.from_time} value={openingHours.saturday.to_time} onChange={(e) => handleHoursChange('saturday', 'to_time', e.target.value)}></input>
            </div>
        </div>
        <div className='horizontal time' style={{justifyContent: 'space-between'}}>
            <p style={{margin: 0}}>Sun</p>
            <div className='horizontal time-cont'>
                <input type='time' className='create-input' style={{margin: 0}} max={openingHours.sunday.to_time} value={openingHours.sunday.from_time} onChange={(e) => handleHoursChange('sunday', 'from_time', e.target.value)}></input>
                <p style={{margin: '0 5px'}}>-</p>
                <input type='time' className='create-input' style={{margin: 0}} min={openingHours.sunday.from_time} value={openingHours.sunday.to_time} onChange={(e) => handleHoursChange('sunday', 'to_time', e.target.value)}></input>
            </div>
        </div>
        <div className="horizontal" style={{margin: "10px 0 0 0", justifyContent: "space-between"}}>
            <button type='submit'>{t('update')}</button>
            {isError && <p style={{color: '#ef3030', margin: 0}}>{t('something_went_wrong')}...</p>}
            {isSubmitting && <p style={{margin: 0}}>{t('updating')}...</p>}
            {isSubmitted && <p style={{color: '#16d316', margin: 0}}>{t('updated_')}</p>}
        </div>
        </form>
    )
}