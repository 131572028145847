
import React, { useEffect, useRef, useState } from 'react';
import 'ol/ol.css';
import { Map, View } from 'ol';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import { fromLonLat, toLonLat } from 'ol/proj';
import marker from '../images/marker.png';
import { useTranslation } from 'react-i18next';

export default function LocationMap({ coordinates, isMapLoading, sendCoords }) {
    const mapRef = useRef();
    const [isLoading, setIsLoading] = useState(true);
    const [markerCoords, setMarkerCoords] = useState(coordinates);
    const {t} = useTranslation();

    useEffect(() => {
        const initialCoordinates = fromLonLat([coordinates.lon, coordinates.lat]);

        // Create a map instance
        const map = new Map({
            target: mapRef.current,
            layers: [
                new TileLayer({
                    source: new OSM(),
                }),
            ],
            view: new View({
                center: initialCoordinates,
                zoom: 12,
            }),
        });

        const updateMarkerPosition = () => {
            const view = map.getView();
            const center = view.getCenter();
            if (center) {
                const lonLat = toLonLat(center);
                setMarkerCoords({lon: lonLat[0], lat: lonLat[1]});
            }
        }

        map.on('moveend', updateMarkerPosition);
        map.on('change:resolution', updateMarkerPosition);

        map.once('rendercomplete', () => {
            setIsLoading(false);
        });

        // Clean up on unmount
        return () => {
            map.setTarget(null);
            map.un('moveend', updateMarkerPosition);
            map.un('change:resolution', updateMarkerPosition);
        }
    }, [coordinates]);

    useEffect(() => {
        sendCoords(markerCoords);
        // eslint-disable-next-line
    }, [markerCoords])

    return (
        <div className='pan' style={{width: "100%", borderRadius: 10, height: "70vw", maxHeight: "300px", margin: "10px 0 10px 0", backgroundColor: "#aad3e0"}}>
            <div className='map-wrapper' style={{position: 'relative', width: '100%', height: '100%'}}>
                <img src={marker} alt='' draggable="false" className='fake-marker' style={{
                    position: 'absolute', 
                    top: '50%', 
                    left: '50%',
                    height: 30,
                    transform: 'translate(-50%, calc(-50% - 15px))', 
                    display: isLoading || isMapLoading ? 'none' : 'flex',
                    zIndex: '999',
                    userSelect: 'none',
                    pointerEvents: 'none'
                }} />
                <div className='locationMap' style={{display: isLoading || isMapLoading ? 'flex' : 'none'}}>{t('loading_map')}...</div>
                <div ref={mapRef} style={{ width: '100%', height: '100%', visibility: isLoading || isMapLoading ? 'hidden' : 'visible' }}></div>
            </div>
        </div>
    )
};
