
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useOrganization, useUser, useClerk } from "@clerk/clerk-react";
import { Helmet } from 'react-helmet-async';
import arrow from '../images/Vector 4.svg';
import {t} from 'i18next'

export default function Join() {
    const [primaryEmail, setPrimaryEmail] = useState(null);
    const { isSignedIn, user } = useUser();
    const navigate = useNavigate();
    const [companies, setCompanies] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isJoinActive, setIsJoinActive] = useState(false);
    const [isCreateActive, setIsCreateActive] = useState(false);
    const [companyToJoin, setCompanyToJoin] = useState(null);
    const [phone, setPhone] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isComplete, setIsComplete] = useState(false);
    const [isError, setIsError] = useState(false);
    const [companyName, setCompanyName] = useState('');
    const [email, setEmail] = useState('');
    const [website, setWebsite] = useState('');
    const [companyToJoinWebsite, setCompanyToJoinWebsite] = useState('');
    const {redirectToSignIn} = useClerk();
    const org = useOrganization();

    async function fetchCompanies() {
        try {
            const response = await fetch("https://bptestapi-e7db5f925e70.herokuapp.com/v4/companies/")
            const data = await response.json();
            setCompanies(data);
            setIsLoading(false);
        } catch (error) {
            console.log('error fetching companies: ', error);
        }
    }

    useEffect(() => {
        if (isSignedIn) {
            if (user.organizationMemberships) {
                if (user.organizationMemberships.length > 0) {
                if (org.isLoaded) {
                    if (org.organization) {
                        navigate(`/update/${org.organization.id}`)
                    } else {
                        navigate(`/update/${user.organizationMemberships[0].organization.id}`)
                    }
                }
                }
            }
        }
        if (isSignedIn) {
            setPrimaryEmail(user.primaryEmailAddress.emailAddress);
            setEmail(user.primaryEmailAddress.emailAddress);
            localStorage.setItem("signInState", "signedIn");
            fetchCompanies();
        }
        // eslint-disable-next-line
    }, [user, org.isLoaded])

    async function handleJoin(e) {
        e.preventDefault();
        setIsSubmitting(true);
        
        const data = {
            company_name: companyToJoin,
            company_website: companyToJoinWebsite,
            contact_phone: phone,
            contact_email: primaryEmail,
            join_or_create: "Join"
        }
        const url = "https://bptestapi-e7db5f925e70.herokuapp.com/leads/v1/form/";
        
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            });
            if (!response.ok) {
                setIsSubmitting(false);
                setIsError(true);
                console.log(`HTTP error! status: `, response);
            } else {
                setIsSubmitting(false);
                setIsComplete(true);
            }
        } catch (error) {
            setIsSubmitting(false);
            setIsError(true);
            console.log("Error fetching: ", error);
        }
    }

    async function handleCreate(e) {
        e.preventDefault();
        setIsSubmitting(true);

        const data = {
            company_name: companyName,
            company_website: website,
            contact_phone: phone,
            contact_email: email,
            join_or_create: "Create"
        }
        const url = "https://bptestapi-e7db5f925e70.herokuapp.com/leads/v1/form/";

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            });
            if (!response.ok) {
                setIsSubmitting(false);
                setIsError(true);
                console.log(`HTTP error! response: `, response);
            } else {
                setIsSubmitting(false);
                setIsComplete(true);
            }
        } catch (error) {
            setIsSubmitting(false);
            setIsError(true);
            console.log("Error fetching: ", error);
        }
    }

    useEffect(() => {
        if (!isSignedIn) {
            const signInState = localStorage.getItem("signInState");
            if (!signInState || signInState !== "signIn") {
                localStorage.setItem("signInState", "signIn");
                setTimeout(() => {
                    if(!isSignedIn) {
                        redirectToSignIn({redirectUrl: "/"});
                    }
                }, 1000)
                // redirectToSignIn({ signInFallbackRedirectUrl: '/join', signInForceRedirectUrl: "/join" });
            } else {
                localStorage.setItem("signInState", "redirect");
                setTimeout(() => {
                    navigate("/");
                }, 1000)
                // navigate("/");
            }
        } else {
            localStorage.setItem("signInState", "signedIn");
        }
        // eslint-disable-next-line
    }, [isSignedIn])

    if (isLoading) {
        return (
            <div className="loading-container">
                <h1>{t('loading')}...</h1>
            </div>
        )
    }

    return (
        <>
        <Helmet>
            <title>{t('join_company')} - Berryprice</title>
        </Helmet>
        <div className="join">
            <h1>{t('join_company')}</h1>
            {!isCreateActive && !isJoinActive ? (
            <div className="card" style={{maxWidth: 600, width: "100%"}}>
                <h2>{t('join_connected_company')}</h2>
                {companies.map((company) => (
                <div key={company.company_id} className="horizontal" style={{justifyContent: "space-between"}}>
                    <p>{company.company_name}</p>
                    <button onClick={() => {
                        setIsJoinActive(true);
                        setCompanyToJoin(company.company_name);
                        setCompanyToJoinWebsite(company.company_website);
                    }}>{t('join')}</button>
                </div>
                ))}
                <div className="line"></div>
                <div className="horizontal" style={{justifyContent: "space-between", alignItems: "center"}}>
                    <p style={{width: "calc(100% - 140px)"}}>{t('is_your_company_missing')}?</p>
                    <button onClick={() => {
                        setIsCreateActive(true);
                    }} style={{marginLeft: 10}}>+ {t('add_company')}</button>
                </div>
            </div>
            ) : null}
            {isJoinActive ? (
                <div className="card" style={{maxWidth: 600, width: "100%"}}>
                    <h2>{t('join_')}{companyToJoin}</h2>
                    {!isComplete ? (
                        <form onSubmit={(e) => handleJoin(e)}>
                            <p>{primaryEmail}</p>
                            <input className="create-input" type="tel" pattern="^\+[0-9]{1,3}[1-9][0-9]+$" required placeholder={t('telephone_(e.g.') + " +46XXXXXXXXX)"} value={phone} onChange={(e) => setPhone(e.target.value)}></input>
                            <div className="line"></div>
                            <div className="horizontal" style={{justifyContent: "space-between"}}>
                                <h3
                                onClick={() => {
                                    setIsJoinActive(false);
                                }}
                                className="no-button"
                                style={{display: "flex", alignItems: "center", margin: "14px 0 0 0"}}
                                >
                                    <img src={arrow} alt="arrow" style={{transform: "rotate(90deg)", height: "0.5em", margin: "0 5px 0 0"}} className="arrow"></img>
                                    {t('back')}
                                </h3>
                                {isSubmitting ? (
                                    <p>{t('sending_request')}...</p>
                                ) : isError ? (
                                    <p style={{color: "#ef3030"}}>{t('something_went_wrong')}...</p>
                                ) : (
                                    <button>{t('join')}</button>
                                )}
                            </div>
                        </form>
                    ) : (
                        <div>
                            <p>{t('we_will_reach_out_to_you_as_soon_as_possible')}.</p>
                            <div className="line"></div>
                            <div className="horizontal" style={{justifyContent: "space-between"}}>
                                <h3
                                onClick={() => {
                                    setIsComplete(false);
                                    setIsJoinActive(false);
                                    navigate("/");
                                }}
                                className="no-button"
                                style={{display: "flex", alignItems: "center", margin: "14px 0 0 0"}}
                                >
                                    <img src={arrow} alt="arrow" style={{transform: "rotate(90deg)", height: "0.5em", margin: "0 5px 0 0"}} className="arrow"></img>
                                    {t('back')}
                                </h3>
                            </div>
                        </div>
                    )}
                </div>
            ) : null}
            {isCreateActive ? (
                <div className="card" style={{maxWidth: 600, width: "100%"}}>
                    <h2>{t('add_company')}</h2>
                    {!isComplete ? (
                        <form onSubmit={(e) => handleCreate(e)}>
                            <input type="text" required className="create-input" placeholder={t('company_name')} value={companyName} onChange={(e) => setCompanyName(e.target.value)}></input>
                            <input className="create-input" type="email" required placeholder={t('email')} value={email} onChange={(e) => setEmail(e.target.value)}></input>
                            <input className="create-input" type="tel" pattern="^\+[0-9]{1,3}[1-9][0-9]+$" required placeholder={t('telephone_(e.g.') + " +46XXXXXXXXX)"} value={phone} onChange={(e) => setPhone(e.target.value)}></input>
                            <input className="create-input" type="text" required placeholder={t('website')} value={website} onChange={(e) => setWebsite(e.target.value)}></input>
                            <div className="line"></div>
                            <div className="horizontal" style={{justifyContent: "space-between"}}>
                                <h3
                                onClick={() => {
                                    setIsCreateActive(false);
                                }}
                                className="no-button"
                                style={{display: "flex", alignItems: "center", margin: "14px 0 0 0"}}
                                >
                                    <img src={arrow} alt="arrow" style={{transform: "rotate(90deg)", height: "0.5em", margin: "0 5px 0 0"}} className="arrow"></img>
                                    {t('back')}
                                </h3>
                                {isSubmitting ? (
                                    <p>{t('sending_request')}...</p>
                                ) : isError ? (
                                    <p style={{color: "#ef3030"}}>{t('something_went_wrong')}...</p>
                                ) : (
                                    <button>{t('confirm')}</button>
                                )}
                            </div>
                        </form>
                    ) : (
                        <div>
                            <p>{t('we_will_reach_out_to_you_as_soon_as_possible')}</p>
                            <div className="line"></div>
                            <div className="horizontal" style={{justifyContent: "space-between"}}>
                                <h3
                                onClick={() => {
                                    setIsComplete(false);
                                    setIsCreateActive(false);
                                    navigate("/");
                                }}
                                className="no-button"
                                style={{display: "flex", alignItems: "center", margin: "14px 0 0 0"}}
                                >
                                    <img src={arrow} alt="arrow" style={{transform: "rotate(90deg)", height: "0.5em", margin: "0 5px 0 0"}} className="arrow"></img>
                                    {t('back')}
                                </h3>
                            </div>
                        </div>
                    )}
                </div>
            ) : null}
        </div>
        </>
    )
}
