
import { useEffect } from 'react';
import arrow from '../images/Vector 4.svg';

export default function Tutorial({ steps, currentStep, nextStep, prevStep }) {
    const step = steps[currentStep];
    const element = document.querySelector(step.element);
    const boundingRect = element ? element.getBoundingClientRect() : { top: 0, left: 0, width: 0, height: 0 };
    
    useEffect(() => {
        if (currentStep === 0 || currentStep === steps.length - 1) {
            document.querySelector('.highlight').style.padding = "0";
            document.querySelector('.highlight').style.transform = 'translate(0,0)';
        } else {
            document.querySelector('.highlight').style.padding = "10px";
            document.querySelector('.highlight').style.transform = 'translate(-10px,-10px)';
        }
        // eslint-disable-next-line
    }, [currentStep])

    return (
        <div className="overlay-container no-select">
            <div className="overlay no-select">
                <div className="highlight no-select" style={{ top: boundingRect.top, left: boundingRect.left, width: boundingRect.width, height: boundingRect.height }}></div>
                <div className='description no-select'>
                    <div className="text horizontal">
                        {step.description}
                        {currentStep === 2 && <div style={{backgroundColor: "#3030ef", borderRadius: "50%", width: "1em", height: "1em", margin: "0 0px 0 5px"}}></div>}
                        {currentStep === 3 && <div style={{backgroundColor: "#ef3030", borderRadius: "50%", width: "1em", height: "1em", margin: "0 0px 0 5px"}}></div>}
                    </div>
                    <div className='horizontal no-select'>
                        <div className="prev-button" onClick={prevStep}>
                            <img src={arrow} alt='prev' style={{transform: 'rotate(90deg)', height: 10}}></img>
                        </div>
                        <div className='steps'>
                            {currentStep+1} / {steps.length}
                        </div>
                        <div className="next-button" onClick={nextStep}>
                            {currentStep === steps.length -1 ? (
                                "Finish"
                            ) : (
                                <img src={arrow} alt='next' style={{transform: 'rotate(-90deg)', height: 10}}></img>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
