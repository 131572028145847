
import { Helmet } from "react-helmet-async"
import { t } from "i18next"

export default function Contact() {
    return (
        <>
        <Helmet>
            <title>{t('contact')} - Berryprice</title>
        </Helmet>
        <div className="update" style={{flex: 1, maxWidth: 600}}>
            <h1>{t('contact')}</h1>
            <div class="card">
                <h3 style={{marginTop: 0}}>{t('company_website')}</h3>
                {/* eslint-disable-next-line */}
                <a href="https://nostechnology.com" target="_blank">nostechnology.com</a>
                <h3>{t('general_inquiries')}</h3>
                <a href="mailto:info@nostechnology.com">info@nostechnology.com</a>
                <h3>{t('support')}</h3>
                <a href="mailto:support@nostechnology.com">support@nostechnology.com</a>
            </div>
        </div>
        </>
    )
}

