
import { Helmet } from "react-helmet-async"

export default function Terms() {
    return (
        <>
        <Helmet>
            <title>Terms & conditions - Berryprice</title>
        </Helmet>
        <div className="update" style={{flex: 1}}>
            <h1>Terms & conditions</h1>
            <div class="card" style={{maxWidth: 800, textAlign: 'justify'}}>
            <h2>1. Scope, User Eligibility, and Registration</h2>

            <h3>1.1 Scope</h3>
            <p>
                Berryprice.com by NOS Technology AB (the "Company") is a site facilitating for market participants (sellers and buyers) to understand prices of raw materials, mainly for berries. Buyers upload their prices and locations for sellers to see where they can sell products. The Company is not involved in any transactions between buyers and sellers. The contract for sale is directly between the seller and the buyer.
            </p>

            <h3>1.2 Age Requirement</h3>
            <p>
                Users must be at least 18 years old to register and use the services provided by the Company.
            </p>

            <h3>1.3 Registration Process</h3>
            <p>
                To register an account, users must provide accurate and complete information, including a valid email address and any other required business or personal details. Users agree to update their information promptly if there are any changes.
            </p>

            <h3>1.4 Account Responsibility</h3>
            <p>
                Users are responsible for maintaining the confidentiality of their account information and are liable for all activities conducted through their accounts. Users must notify the Company immediately of any unauthorized use of their account.
            </p>

            <h2>2. Privacy Policy</h2>
            <p>
                For information on how we collect, use, and protect your personal data, please refer to our Privacy Policy. The Privacy Policy forms an integral part of these terms and conditions.
            </p>

            <h2>3. User Conduct</h2>

            <h3>3.1 Prohibited Activities</h3>
            <p>Users agree not to:</p>
            <ul>
                <li>Post false, inaccurate, or misleading information.</li>
                <li>Engage in activities that violate any law or regulation.</li>
                <li>Spam, harass, or abuse other users.</li>
                <li>Post defamatory, libelous, or otherwise offensive content.</li>
            </ul>

            <h3>3.2 Content Standards</h3>
            <p>
                All content posted must be relevant, accurate, and legal. Users must ensure that their listings for raw materials are truthful and reflect the current status and price of the materials.
            </p>

            <h3>3.3 Consequences for Violations</h3>
            <p>
                The Company reserves the right to suspend or terminate accounts and remove any content that violates these terms and conditions.
            </p>

            <h2>4. Posting and Listing Rules</h2>

            <h3>4.1 Accuracy of Information</h3>
            <p>
                Users must ensure that all information provided in their listings is accurate and up-to-date. Misleading or false information is strictly prohibited.
            </p>

            <h3>4.2 Listing Requirements</h3>
            <p>
                Listings for raw materials must include accurate descriptions, pricing, and any applicable terms and conditions of the sale.
            </p>

            <h3>4.3 Removal or Editing of Listings</h3>
            <p>
                The Company reserves the right to remove or edit any listings that do not comply with these terms and conditions.
            </p>

            <h2>5. Transaction Policies</h2>

            <h3>5.1 Role of the Company</h3>
            <p>
                The Company acts solely as a platform provider and is not a party to transactions between buyers and sellers. The Company does not guarantee the accuracy of listings or the performance of either party in a transaction.
            </p>

            <h3>5.2 Payment Terms</h3>
            <p>
                The Company does not facilitate payments between buyers and sellers. Payment terms, methods, and any associated fees must be arranged directly between the buyers and sellers.
            </p>

            <h3>5.3 Dispute Resolution</h3>
            <p>
                The Company does not involve itself in disputes between buyers and sellers. Users are responsible for resolving any disputes that arise from transactions independently.
            </p>

            <h2>6. Intellectual Property</h2>

            <h3>6.1 Content Ownership</h3>
            <p>
                Users retain ownership of the content they post. By posting content, users grant the Company a non-exclusive, worldwide, royalty-free license to use, display, and distribute the content in connection with the operation and promotion of the site.
            </p>

            <h3>6.2 User Content</h3>
            <p>
                Users warrant that they own or have the right to use any content they post and that their postings do not infringe on any intellectual property rights of third parties.
            </p>

            <h2>7. Limitation of Liability</h2>

            <h3>7.1 Disclaimer of Warranties</h3>
            <p>
                The Company provides the site and services on an "as is" and "as available" basis. The Company disclaims all warranties, express or implied, including but not limited to warranties of accuracy, reliability, and fitness for a particular purpose.
            </p>

            <h3>7.2 Limitation on Liability</h3>
            <p>
                The Company shall not be liable for any damages, including direct, indirect, incidental, punitive, and consequential damages, arising from the use of the site or services.
            </p>

            <h2>8. Modification of Terms</h2>

            <h3>8.1 Right to Modify</h3>
            <p>
                The Company reserves the right to modify these terms and conditions at any time.
            </p>

            <h3>8.2 Notice of Changes</h3>
            <p>
                Users will be notified of any changes to these terms and conditions through the site or via email. Continued use of the site constitutes acceptance of the modified terms.
            </p>

            <h2>9. Termination of Use</h2>

            <h3>9.1 Termination Rights</h3>
            <p>
                The Company may terminate or suspend a user's access to the site at any time, with or without cause or notice.
            </p>

            <h3>9.2 Effect of Termination</h3>
            <p>
                Upon termination, the user’s right to use the site will immediately cease. All provisions of these terms that by their nature should survive termination shall survive, including ownership provisions, warranty disclaimers, indemnity, and limitations of liability.
            </p>

            <h2>10. Governing Law and Jurisdiction</h2>

            <h3>10.1 Applicable Law</h3>
            <p>
                These terms and conditions are governed by and construed in accordance with the laws of Sweden, European Union.
            </p>

            <h3>10.2 Jurisdiction</h3>
            <p>
                Any disputes arising out of or in connection with these terms and conditions shall be subject to the exclusive jurisdiction of the courts of Sweden.
            </p>

            <h2>11. Contact Information</h2>
            <p>
                For any questions or support needs, users can contact the Company at:
                <br />
                NOS Technology AB (559438-2482)
                <br />
                <a href="mailto:info@nostechnology.com">info@nostechnology.com</a>
            </p>
            </div>
        </div>
        </>
    )
}

