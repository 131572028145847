import React, { useEffect, useState } from 'react'
import 'ol/ol.css';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { fromLonLat } from 'ol/proj';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Icon, Style } from 'ol/style';
import Overlay from 'ol/Overlay';
import CurrentLocation from '../images/currentLocation.png'
import { useTranslation } from 'react-i18next';
import directions from '../images/Group 4.svg';

function MapComp(props) {
    const {t} = useTranslation();
    const [clientCoords, setClientCoords] = useState({
        lat: 64,
        lon: 21
    })
    const [isLoading, setIsLoading] = useState(true);
    const [popup, setPopup] = useState(null);

    function calcPrice(buyer, berry, country) {
        if (buyer.location_currency) {
          if (props.currency === "kr") {
            if (buyer.location_currency === "EUR") return (Math.round((buyer.berry_prices.country[country].prices[berry] * 11.54) * 100) / 100).toString();
          } else if (buyer.location_currency === "SEK") return (Math.round((buyer.berry_prices.country[country].prices[berry] / 11.54) * 100) / 100).toString();
        }
        return (Math.round((buyer.berry_prices.country[country].prices[berry]) * 100) / 100).toString();
    }

    function isString(value) {
        return typeof value === 'string' || value instanceof String;
    }

    const handleClick = (event) => {
        if (popup) popup.style.display = 'none';
        const className = event.target.className;
        const startIndex = isString(className) ? className.indexOf('id_') : -1;
        if (startIndex !== -1) {
            const endIndex = className.indexOf(' ', startIndex);
            const id = (endIndex === -1) 
            ? className.substring(startIndex + 3)
            : className.substring(startIndex + 3, endIndex);
            const element = document.getElementById(id);
            setPopup(element);
            element.style.display = 'block';
        }
    };

    document.addEventListener('click', handleClick);

    function calcHoursSinceDate (dateString) {
        // Parse the received date string
        const pastDate = new Date(dateString + 'Z');
    
        // Get the current date and time
        const currentDate = new Date();
    
        // Calculate the difference in milliseconds
        const diffInMilliseconds = currentDate - pastDate;
    
        // Convert the difference from milliseconds to hours
        const diffInHours = Math.round(diffInMilliseconds / (1000 * 60 * 60));
    
        if (diffInHours > 72) return ('>72')
    
        return diffInHours;
    };

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                setClientCoords({
                  lat: position.coords.latitude,
                  lon: position.coords.longitude
                });
            },
            (err) => {
              console.error(err.message);
            })
          } else {
            console.error("Geolocation not supported");
          }
    }, [])

    useEffect(() => {
        const map = new Map({
            target: 'map',
            layers: [
                new TileLayer({
                    source: new OSM(),
                })
            ],
            view: new View({
                center: fromLonLat([clientCoords.lon, clientCoords.lat]),
                zoom: 6,
            }),
        });

        const buyers = props.buyers;
        const markerCoords = [];
        const country = props.country;

        Object.keys(buyers).forEach((key) => {
            markerCoords.push({
                name: buyers[key].location_name,
                coordinates: [
                    buyers[key].location_longitude,
                    buyers[key].location_latitude
                ],
                text: buyers[key].location_name,
                lingonberry: buyers[key].berry_prices && buyers[key].berry_prices.country[country] && buyers[key].berry_prices.country[country].prices.lingonberry,
                blueberry: buyers[key].berry_prices && buyers[key].berry_prices.country[country] && buyers[key].berry_prices.country[country].prices.bilberry,
                buyer: buyers[key]
            })
        })

        const vectorLayer = new VectorLayer({
            source: new VectorSource({
                features: markerCoords.map(marker => {
                    const feature = new Feature({})
                    feature.setProperties(marker);
                    return feature;
                })
            })
        })

        // Marker
        const addOverlay = (coords, lingonPrice, bluePrice, name, buyer) => {
            const element = document.createElement('div');
            element.className = `custom-marker id_${buyer.location_id}`;
            const triangle = document.createElement('div');
            triangle.className = `triangle id_${buyer.location_id}`;
            const cont = document.createElement('div');
            cont.className = `marker-cont id_${buyer.location_id}`;
            
            let currency = props.currency;

            if (bluePrice > 0) {
                const blueCont = document.createElement('div');
                blueCont.className = `horizontal id_${buyer.location_id}`;
                const blue = document.createElement('div');
                blue.className = `berry-symbol blue id_${buyer.location_id}`;
                const blueText = document.createElement('p');
                blueText.className = `marker-text id_${buyer.location_id}`;
                blueText.innerHTML = currency === "kr" ? calcPrice(buyer, "bilberry", country) + currency : currency + calcPrice(buyer, "bilberry", country);
                blueCont.appendChild(blue);
                blueCont.appendChild(blueText);
                element.appendChild(blueCont);
            }

            if (lingonPrice > 0) {
                const lingonCont = document.createElement('div');
                lingonCont.className = `horizontal id_${buyer.location_id}`;
                const lingon = document.createElement('div');
                lingon.className = `berry-symbol red id_${buyer.location_id}`;
                const lingonText = document.createElement('p');
                lingonText.className = `marker-text id_${buyer.location_id}`;
                lingonText.innerHTML = currency === "kr" ? calcPrice(buyer, "lingonberry", country) + currency : currency + calcPrice(buyer, "lingonberry", country);
                lingonCont.appendChild(lingon);
                lingonCont.appendChild(lingonText);
                element.appendChild(lingonCont);
            }

            cont.appendChild(element);
            cont.appendChild(triangle);
        
            const overlay = new Overlay({
                element: cont,
                position: fromLonLat(coords),
                positioning: 'bottom-center',
                className: "marker-overlay"
            });
        
            map.addOverlay(overlay);
        };

        // Popup
        const addPopup = (coords, buyer) => {
            const popup = document.createElement('div');
            popup.className = 'popup';
            
            // Name element
            const name = document.createElement('p');
            name.style.fontWeight = '600';
            name.style.marginTop = 0;
            name.innerHTML = buyer.location_name;
            popup.appendChild(name);

            // Updated
            const updated = document.createElement('p');
            updated.style.margin = '0 0 5px 0';
            const hours = buyer.berry_prices && buyer.berry_prices.country[country] && buyer.berry_prices.country[country].updated_info
                ? calcHoursSinceDate(buyer.berry_prices.country[country].updated_info.update_timestamp)
                : null;
            updated.innerHTML = t('updated_') + hours + 'h ' + t('ago');
            popup.appendChild(updated);

            // Address and directions link
            const addressLink = document.createElement('a');
            addressLink.target = '_blank';
            addressLink.href = `https://www.google.com/maps?q=${buyer.location_latitude},${buyer.location_longitude}`;
            addressLink.style.display = 'flex';
            addressLink.style.flexWrap = 'wrap';
            
            const address = document.createElement('p');
            address.style.margin = '0 5px 0 0';
            address.innerHTML = buyer.location_address;
            
            const directionsImg = document.createElement('img');
            directionsImg.src = directions;
            directionsImg.alt = 'directions';
            directionsImg.style.height = '1em';
            directionsImg.style.width = '1em';
            directionsImg.style.margin = '0 0px 0 0';
            
            addressLink.appendChild(address);
            addressLink.appendChild(directionsImg);
            popup.appendChild(addressLink);
            
            // Website link (if exists)
            if (buyer.location_website) {
                const websiteLink = document.createElement('a');
                websiteLink.target = '_blank';
                const website = buyer.location_website;
                websiteLink.href = website.startsWith('http://') || website.startsWith('https://') ? website : `http://${website}`;
                websiteLink.innerHTML = website;
                popup.appendChild(websiteLink);
            }
            
            // Email link
            const emailLink = document.createElement('a');
            emailLink.href = `mailto:${buyer.location_email}`;
            emailLink.innerHTML = buyer.location_email;
            popup.appendChild(emailLink);
            
            // Phone link
            const phoneLink = document.createElement('a');
            phoneLink.href = `tel:${buyer.location_phone}`;
            phoneLink.innerHTML = buyer.location_phone;
            phoneLink.style.marginBottom = 0;
            popup.appendChild(phoneLink);
            
            // Container for the popup and triangle
            const cont = document.createElement('div');
            cont.className = 'popup-cont';
            cont.id = buyer.location_id;
            
            const triangle = document.createElement('div');
            triangle.className = 'triangle flip';
            triangle.style.top = '-5px';
            
            cont.appendChild(popup);
            cont.appendChild(triangle);
        
            const overlay = new Overlay({
                element: cont,
                position: fromLonLat(coords),
                positioning: 'top-center',
                className: 'popup-overlay'
            });
        
            map.addOverlay(overlay);
        };

        markerCoords.forEach(marker => {
            addPopup(marker.coordinates, marker.buyer);
            addOverlay(marker.coordinates, marker.lingonberry, marker.blueberry, marker.name, marker.buyer);
        });
        
        map.addLayer(vectorLayer);

        if (clientCoords.lat !== 64) {
            const userLocation = new Feature({
                geometry: new Point(fromLonLat([clientCoords.lon,clientCoords.lat]))
            })
            userLocation.setStyle(
                new Style({
                    image: new Icon({
                        crossOrigin: 'anonymous',
                        src: CurrentLocation,
                        scale: 0.015
                    })
                })
            )
            vectorLayer.getSource().addFeature(userLocation);
        }

        map.on('rendercomplete', () => {
            setIsLoading(false);
        });

        return () => {
            map.setTarget(undefined);
        }
        // eslint-disable-next-line
    }, [clientCoords, props.buyers])
    
    return (
        <>
            <div className='map'>
                <div className='locationMap' style={{display: isLoading ? 'flex' : 'none', top: 0}}>{t('loading_map')}...</div>
                <div id='map' style={{visibility: isLoading ? 'hidden' : 'visible', width: '100%', height: '100%'}}></div>
            </div>
        </>
    )

}

export default MapComp