
import MapComp from "./MapComp";
import { t } from "i18next";
import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
import Locations from "./Locations";
import arrow from '../images/Vector 4.svg';

export default function Start({orgId}) {
    // States etc.
    const [country, setCountry] = useState("");
    const [isFiltersOpen, setIsFiltersOpen] = useState(true);
    const [isSortByOpen, setIsSortByOpen] = useState(false);
    const [filters, setFilters] = useState({
        blueberries: true,
        lingonberries: true,
        distance: false,
        distanceRange: [0, 20000],
        recentlyUpdated: false
    });
    const [sortBy, setSortBy] = useState('priceBlueberries');
    const [swedishCompanies, setSwedishCompanies] = useState([]);
    const [finnishCompanies, setFinnishCompanies] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [sortedCompanies, setSortedCompanies] = useState([]);
    const [clientCoords, setClientCoords] = useState({
        lat: null,
        lon: null
    });
    const [closestLocationDistances, setClosestLocationDistances] = useState({});
    const [locations, setLocations] = useState([]);
    const [geoStatus, setGeoStatus] = useState("loading");
    const [loadingStatus, setLoadingStatus] = useState(0);

    // Handle country change
    function handleCountryChange(country) {
        setCountry(country);
        localStorage.setItem('country', country);
    }

    // Handle filter click
    const handleFiltersClick = () => {
        if (isFiltersOpen) localStorage.setItem('hasFiltersBeenClosed', true);
        setIsFiltersOpen(!isFiltersOpen);
    };
    
    // Handle sort by click
    const handleSortByClick = () => {
        setIsSortByOpen(!isSortByOpen);
    };

    // Handle input number change
    function handleInputNumberChange(filter, value, index) {
        setFilters((prevFilters) => {
            const newRange = [...prevFilters[`${filter}`]];
            newRange[index] = value;
            return { ...prevFilters, [`${filter}`]: newRange };
        });
    }

    // GET Companies
    function getCompanies() {
        setLoadingStatus(loadingStatus - 1);
        // Swedish
        fetch('https://bptestapi-e7db5f925e70.herokuapp.com/v4/companies/Sweden')
        .then(response => response.json())
        .then(data => {
            setSwedishCompanies(data);
            setLoadingStatus(loadingStatus + 1);
        })
        .catch(error => {
            console.log("Error fetching data: ", error);
            setLoadingStatus("err");
        })

        // Finnish
        setLoadingStatus(loadingStatus - 1);
        fetch('https://bptestapi-e7db5f925e70.herokuapp.com/v4/companies/Finland')
        .then(response => response.json())
        .then(data => {
            setFinnishCompanies(data);
            setLoadingStatus(loadingStatus + 1);
        })
        .catch(error => {
            console.log("Error fetching data: ", error);
            setLoadingStatus("err");
        })
    }

    // GET User location
    function getUserLocation() {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              setClientCoords({
                lat: position.coords.latitude,
                lon: position.coords.longitude
              });
              setGeoStatus('complete');
          },
          (err) => {
            console.error(err.message);
            setGeoStatus('error');
          })
        } else {
          console.error("Geolocation not supported");
          setGeoStatus('error');
        }
    }

    // Calculate distance from coords
    function calcDists(lat1, lon1, lat2, lon2) {
        if (!lat1 || !lon1 || !lat2 || !lon2) return "--"
        const radlat1 = Math.PI * lat1 / 180;
        const radlat2 = Math.PI * lat2 / 180;
        const radlon1 = Math.PI * lon1 / 180;
        const radlon2 = Math.PI * lon2 / 180;
    
        const dlat = radlat2 - radlat1;
        const dlon = radlon2 - radlon1;
    
        const a = Math.sin(dlat/2) * Math.sin(dlat/2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.sin(dlon/2) * Math.sin(dlon/2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
        const distance = 6371 * c;
    
        return Math.round(distance);
    }

    // Set closest location distances
    function closestLocDists(companies) {
        let cloLocDists = {};
        companies.forEach(company => {
            const id = company.company_id;
            const locations = company.company_locations;

            locations.forEach(location => {
                const active = location.active_bool;
                const locationDist = calcDists(clientCoords.lat, clientCoords.lon, location.location_latitude, location.location_longitude);
                const currentlyClosestDist = cloLocDists[id];
                if (active !== false && ( !currentlyClosestDist || locationDist < currentlyClosestDist) ) cloLocDists[id] = locationDist;
            })
        });
        return cloLocDists;
    }

    // Get locations
    function getLocations(companies) {
        setLocations([]);
        companies.forEach(company => {
            const companyLocations = company.company_locations.filter(location => location.active_bool !== false);
            setLocations((prevLocations) => ([
                ...prevLocations,
                ...companyLocations
            ]))
        })
    }

    // Switch companies and locations country
    function switchCountry() {
        if (country === "Sweden") setCompanies(swedishCompanies);
        if (country === "Finland") setCompanies(finnishCompanies);
    }

    // Calc hours since date
    function calculateHoursSinceDate(dateString) {
        const pastDate = new Date(dateString + 'Z');
        const currentDate = new Date();
        const diffInMilliseconds = currentDate - pastDate;
        const diffInHours = Math.round(diffInMilliseconds / (1000 * 60 * 60));
        if (diffInHours > 72) return ('>72')
        return diffInHours;
    };

    // Filter companies
    function filterCompanies(companyList) {
        return companyList.filter((key) => {
            let company = companies[key];
            let companyLocs = company.company_locations;
            let allLocsInActive = true;
            
            companyLocs.forEach(location => {
                if (location.active_bool !== false) allLocsInActive = false;
            })
            
            if (allLocsInActive || !company || !company.berry_prices || !company.berry_prices.country || !company.berry_prices.country[country] || !company.berry_prices.country[country].prices) return false;
            
            const buysBlueberries = company.berry_prices.country[country] && company.berry_prices.country[country].prices.bilberry > 0;
            const buysLingonberries = company.berry_prices.country[country] && company.berry_prices.country[country].prices.lingonberry > 0;
        
            // does not buy blueberries and filter for only blueberries
            if (!buysBlueberries && filters.blueberries && !filters.lingonberries) return false;
        
            // does not buy lingonberries and filter for only lingonberries
            if (!buysLingonberries && filters.lingonberries && !filters.blueberries) return false;
        
            // does not buy blueberries or lingonberries
            if (!buysBlueberries && !buysLingonberries) return false;
        
            const distance = closestLocationDistances[company.company_id];
    
            // distance outside distance range and distance filter is on
            if (filters.distance) {
                if (distance < filters.distanceRange[0] || distance > filters.distanceRange[1]) return false;
            }
        
            // hours since update greater than 24 or NaN and recent filter is on
            if (filters.recentlyUpdated) {
                const hoursSinceUpdate = calculateHoursSinceDate(company.berry_prices.country[country].updated_info.update_timestamp);
                if (hoursSinceUpdate > 24 || isNaN(hoursSinceUpdate)) return false;
            }
        
            return true;
        })
    }

    // Sort companies
    function sortCompanies() {
        const filteredCompanies = filterCompanies(Object.keys(companies));
        let filComps = filteredCompanies.map(index => companies[index]);
        const cloLocDists = closestLocDists(filComps);
        const distanceKeys = Object.keys(cloLocDists);
        if (sortBy === 'closest') {
            const sorted = distanceKeys.sort((a, b) => {
                return cloLocDists[a] - cloLocDists[b];
            });
            const newSorted = [];
            for (let i = 0; i < sorted.length; i++) {
                for (let j = 0; j < companies.length; j++) {
                    const companyName = companies[j].company_id;
                    if (companyName === sorted[i]) newSorted.push(j.toString());
                }
            }
            return newSorted.map(index => companies[index]);
        } else if (sortBy === 'priceBlueberries') {
            const sorted = filteredCompanies.sort((a,b) => {
                const priceA = companies[a].berry_prices.country[country].prices.bilberry;
                const priceB = companies[b].berry_prices.country[country].prices.bilberry;
                return priceB - priceA;
            });
            return sorted.map(index => companies[index]);
        } else if (sortBy === 'priceLingonberries') {
            const sorted = filteredCompanies.sort((a,b) => {
                const priceA = companies[a].berry_prices.country[country].prices.lingonberry;
                const priceB = companies[b].berry_prices.country[country].prices.lingonberry;
                return priceB - priceA;
            });
            return sorted.map(index => companies[index]);
        }
    };

    // Sort company locations
    function sortCompanyLocations(companies) {
        const newCompanyList = [];
        companies.forEach(company => {
            const companyLocations = company.company_locations;
            const keys = Object.keys(companyLocations);
            const sorted = keys.sort((a, b) => {
                return calcDists(clientCoords.lat, clientCoords.lon, parseFloat(companyLocations[a].location_latitude), parseFloat(companyLocations[a].location_longitude)) - calcDists(clientCoords.lat, clientCoords.lon, parseFloat(companyLocations[b].location_latitude), parseFloat(companyLocations[b].location_longitude));
            });
            const sortedLocations = sorted.map(index => company.company_locations[index]);
            const newCompany = {...company, company_locations: sortedLocations};
            newCompanyList.push(newCompany);
        })
        setSortedCompanies(newCompanyList);
    }

    // On page load
    useEffect(() => {
        const lsCountry = localStorage.getItem('country');
        setCountry(lsCountry ? lsCountry : "Sweden");
        handleCountryChange(lsCountry ? lsCountry : "Sweden");
        const hasFiltersBeenClosed = localStorage.getItem('hasFiltersBeenClosed');
        setIsFiltersOpen(!hasFiltersBeenClosed);
        getCompanies();
        getUserLocation();
        // eslint-disable-next-line
    }, [])

    // On client coords/companies/country change
    useEffect(() => {
        if (clientCoords.lat && companies.length > 0) {
            let indicies = filterCompanies(Object.keys(companies));
            let filComps = indicies.map(index => companies[index]);
            setClosestLocationDistances(closestLocDists(filComps));
        }
        // eslint-disable-next-line
    }, [clientCoords, companies, sortBy, country])

    // On sortedCompanies change
    useEffect(() => {
        if (sortedCompanies.length > 0) getLocations(sortedCompanies);
    }, [sortedCompanies])

    // On country companies/country change
    useEffect(() => {
        switchCountry();
        // eslint-disable-next-line
    }, [country, finnishCompanies, swedishCompanies])

    // On sortBy, clientCoords, filters, country, companies change
    useEffect(() => {
        if (companies.length > 0) {
            const sortedComps = sortCompanies();
            setSortedCompanies(sortedComps);
            sortCompanyLocations(sortedComps);
        }
        // eslint-disable-next-line
    }, [sortBy, clientCoords, filters, country, companies])

    if (loadingStatus < 0) {
        return (
            <div className="loading-container">
                <h1>{t('loading')}...</h1>
            </div>
        )
    }

    if (loadingStatus === "err") {
        return (
            <div className="loading-container">
                <h1>{t('something_went_wrong')}...</h1>
            </div>
        )
    }

    return (
        <div className="home">
            <Helmet>
                <title>Berryprice</title>
                <meta name="description" content={t('home_description')} />
            </Helmet>
            <MapComp
                buyers={locations}
                currency={country === "Sweden" ? "kr" : "€"}
                country={country}
            />
            <div className="location-loading">
                {geoStatus !== "complete" &&
                <p style={{marginTop: 0}}>
                    {geoStatus === "loading" ? t('loading') + " " + t('position') + "..." : geoStatus === "error" ? t('allow_geolocation_to_see_locations_near_you') : null}
                </p>}
                <div className="locations">
                    <div className="locations-cont hidden-scrollbar" style={{height: geoStatus !== "complete" ? 'calc((100vw - 300px)*0.5 - 65px)' : 'calc((100vw - 300px)*0.5 - 40px)', minHeight: 260}}>
                        {/* Country selector */}
                        <form
                            style={{
                                justifyContent: "space-between",
                                margin: "0 0 15px 0",
                                alignItems: "center"
                                }}
                            className="currency horizontal"
                        >
                            <div className="horizontal" style={{margin: "0 0 15px 0"}}>
                            <label style={{margin: "0 10px 0 0"}} className="container horizontal" htmlFor="SEK">
                                <input id="SEK" type="radio" checked={country === "Sweden"} name="currency" onChange={(e) => {
                                if (e.target.checked) handleCountryChange("Sweden");
                                }}/>
                                <img alt="SE" className="flag" src="https://flagsapi.com/SE/flat/64.png"/>
                            </label>
                            <label style={{margin: "0 10px 0 0px"}} className="container" htmlFor="euro">
                                <input id="euro" type="radio" checked={country === "Finland"} name="currency" onChange={(e) => {
                                if (e.target.checked) handleCountryChange("Finland");
                                }}/>
                                <img alt="FI" className="flag" src="https://flagsapi.com/FI/flat/64.png"/>
                            </label>
                            </div>
                        </form>
                        <div className="line"></div>
                        {/* Filters */}
                        <div className="filter-title" onClick={handleFiltersClick}>
                            <h3 style={{margin: "0 10px 0 0"}}>{t('filters')}</h3>
                            <img src={arrow} alt="arrow" className={`arrow rotatable ${isFiltersOpen ? 'rotated' : ''}`}></img>
                        </div>
                        {isFiltersOpen ? (
                            <form className="berryfilter">
                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "baseline"}}>
                                <label className="container" htmlFor="blueberries">{t('bilberry')} ({t('raw')})
                                <input id="blueberries" type="checkbox" name="filter" checked={filters.blueberries} onChange={(e) => {setFilters((prevFilters) => ({...prevFilters,blueberries: e.target.checked}))}}/>
                                <span className="checkbox" id="blueCheck"></span>
                                </label>
                            </div>
                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "baseline"}}>
                                <label className="container" htmlFor="lingonberries">{t('lingonberry')} ({t('raw')})
                                <input id="lingonberries" type="checkbox" name="filter" checked={filters.lingonberries} onChange={(e) => {setFilters((prevFilters) => ({...prevFilters,lingonberries: e.target.checked}))}}/>
                                <span className="checkbox" id="redCheck"></span>
                                </label>
                            </div>
                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "baseline"}}>
                                <label className="container" htmlFor="distance">{t('distance')}
                                <input id="distance" type="checkbox" name="filter" checked={filters.distance} onChange={(e) => {setFilters((prevFilters) => ({...prevFilters,distance: e.target.checked}))}}/>
                                <span className="checkbox"></span>
                                </label>
                                <div className={!filters.distance ? "inactive" : null}  style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <input className="filter-input" type="number" step="any" value={filters.distanceRange[0]} onChange={(e) => {handleInputNumberChange('distanceRange', parseFloat(e.target.value), 0)}} min={0} max={20000}></input>
                                {" - "}
                                <input className="filter-input" type="number" step="any" value={filters.distanceRange[1]} onChange={(e) => {handleInputNumberChange('distanceRange', parseFloat(e.target.value), 1)}} min={0} max={20000}></input>
                                <p className="unit">km</p>
                                </div>
                            </div>
                            <label className="container" htmlFor="recent">{t('recently_updated')} (24h)
                                <input id="recent" type="checkbox" name="filter" onChange={(e) => {setFilters((prevFilters) => ({...prevFilters,recentlyUpdated: e.target.checked}))}}/>
                                <span className="checkbox"></span>
                            </label>
                            </form>
                        ) : null}
                        {/* Sort by */}
                        <div className="filter-title" onClick={handleSortByClick}>
                            <h3 style={{margin: "0 10px 0 0"}}>{t('sort')}</h3>
                            <img src={arrow} alt="arrow" className={`arrow rotatable ${isSortByOpen ? 'rotated' : ''}`}></img>
                        </div>
                        {isSortByOpen ? (
                            <form className="sortBy">
                            <label className="container" htmlFor="closest">{t('closest')}
                                <input id="closest" type="radio" checked={sortBy === 'closest' ? true : false} name="sortBy" onChange={() => {setSortBy('closest')}}/>
                                <span className="radio"></span>
                            </label>
                            <p>{t('best_price')}:</p>
                            <label className="container" htmlFor="bestPriceBlueberries">{t('bilberry')}
                                <input id="bestPriceBlueberries" type="radio" checked={sortBy === 'priceBlueberries' ? true : false} name="sortBy" onChange={() => {setSortBy('priceBlueberries')}}/>
                                <span className="radio"></span>
                            </label>
                            <label className="container" htmlFor="bestPriceLingonberries">{t('lingonberry')}
                                <input id="bestPriceLingonberries" type="radio" checked={sortBy === 'priceLingonberries' ? true : false} name="sortBy" onChange={() => {setSortBy('priceLingonberries')}}/>
                                <span className="radio"></span>
                            </label>
                            </form>
                        ) : null}
                        <div className="line" style={{margin: "0 0 10px 0"}}></div>
                        {sortedCompanies.length > 0 && (
                        <Locations
                            companies={sortedCompanies}
                            country={country}
                            closestLocationDistances={closestLocationDistances}
                            calcDists={calcDists}
                            clientCoords={clientCoords}
                            orgId={orgId}
                        />
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
