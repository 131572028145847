
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useOrganization, useUser, useSession } from "@clerk/clerk-react";
import { Helmet } from 'react-helmet-async';
import LocationMap from './LocationMap';
import { t } from 'i18next';

export default function NewLocation() {
    const {pathname} = useLocation();
    const id = pathname.substring(17);
    const [isLoading, setIsLoading] = useState(true);
    const [isAuth, setIsAuth] = useState(false);
    const {isSignedIn, user} = useUser();
    const [organization, setOrganization] = useState(null);
    const org = useOrganization();
    const navigate = useNavigate();
    const { isLoaded, error } = useSession();

    const [companyId, setCompanyId] = useState('');
    const [locationName, setLocationName] = useState('');
    const [address, setAddress] = useState('');
    const [country, setCountry] = useState('');
    const [coords, setCoords] = useState({
        lon: null,
        lat: null
    });
    const [finalCoords, setFinalCoords] = useState({
        lon: null,
        lat: null
    })
    const [contactPerson, setContactPerson] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
    const [isMapLoading, setIsMapLoading] = useState(false);
    const [locationWebsite, setLocationWebsite] = useState('');
    const [isError, setIsError] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [prices, setPrices] = useState({});
    const [berryPrices, setBerryPrices] = useState(null);
    const [openingHours, setOpeningHours] = useState({
        monday: {
            from_time: "",
            to_time: ""
        },
        tuesday: {
            from_time: "",
            to_time: ""
        },
        wednesday: {
            from_time: "",
            to_time: ""
        },
        thursday: {
            from_time: "",
            to_time: ""
        },
        friday: {
            from_time: "",
            to_time: ""
        },
        saturday: {
            from_time: "",
            to_time: ""
        },
        sunday: {
            from_time: "",
            to_time: ""
        }
    })

    // get company id from bpapi
    async function fetchCompanyId(org) {
        fetch("https://bptestapi-e7db5f925e70.herokuapp.com/v4/companies/")
        .then(response => response.json())
        .then(data => {
            let comId;
            let companyWebsite;
            for (let i = 0; i < data.length; i++) {
                let companyName = data[i].company_name;
                if (companyName === org.name) {
                    comId = data[i].company_id;
                    companyWebsite = data[i].company_website;
                    setCompanyId(comId);
                    setLocationWebsite(companyWebsite);
                    setBerryPrices(data[i].berry_prices ? data[i].berry_prices.country : null);
                }
            }
        })
        .catch(error => {
          console.log("Error fetching data: ", error);
          setIsLoading(true);
        })
    }

    // check auth
    useEffect(() => {
        if (isLoaded) {
            if (isSignedIn) {
                setEmail(user.primaryEmailAddress.emailAddress);
                if (user.organizationMemberships) {
                    if (user.organizationMemberships.length > 0 && org.isLoaded) {
                        setOrganization(org.organization);
                    }
                }
            }
            checkAuth(org.organization);
        }
        // eslint-disable-next-line
    }, [user, isLoaded])

    useEffect(() => {
        if (isSignedIn) {
          if (user.organizationMemberships) {
            if (user.organizationMemberships.length > 0) {
              if (org.isLoaded) {
                if (org.organization) {
                  setOrganization(org.organization);
                  if (org.organization.id !== id) {
                      navigate(`/create-location/${org.organization.id}`);
                  }
                } else {
                  setOrganization(user.organizationMemberships[0]);
                  if (user.organizationMemberships[0].id !== id) {
                      navigate(`/create-location/${user.organizationMemberships[0].id}`);
                  }
                }
              }
            }
          }
        }
        //eslint-disable-next-line
    }, [user, org.isLoaded])

    function checkAuth(org) {
        if (!isSignedIn) {
            setIsAuth(false);
            navigate('/');
        };
        if (org && org.id === id) {
            setIsAuth(true);
            setIsLoading(false);
            fetchCompanyId(org);
        } else if (org) {
            setIsAuth(false);
            let auth = isAuth;
            setTimeout(() => {
                if (!auth) {
                    navigate(`/update/${org.id}`);
                }
            }, 1000);
        } else {
            setIsAuth(false);
            setTimeout(() => {
                let auth = isAuth;
                if (!auth) {
                    navigate(`/join`);
                }
            }, 1000)
        }
    }

    // set coords
    function getCoordsByAddress(address) {
        setIsMapLoading(true);
        setAddress(address);
        fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${apiKey}`)
        .then(response => response.json())
        .then(data => {
            if (data && data.results && data.results[0] && data.results[0].geometry) {
                setCoords({
                    lon: data.results[0].geometry.location.lng,
                    lat: data.results[0].geometry.location.lat
                });
                setFinalCoords({
                    lon: data.results[0].geometry.location.lng,
                    lat: data.results[0].geometry.location.lat
                });
                setIsMapLoading(false);
                for (const item of data.results[0].address_components) {
                    if (item.types.includes("country") && item.types.includes("political")) {
                        if (item.short_name === "SE") {
                            setCountry("Sweden");
                        }
                        if (item.short_name === "FI") {
                            setCountry("Finland");
                        }
                        break;
                    }
                }
            }
        })
        .catch(error => {
          console.log("Error fetching google maps: ", error);
          setIsMapLoading(false);
        })
    }

    function handleOptionChange(value) {
        setCountry(value);
    }

    // submit
    async function handleSubmit() {
        setIsError(false);
        setIsSubmitting(true);

        const url = "https://bptestapi-e7db5f925e70.herokuapp.com/v4/locations/";
        const data = {
            company_id: companyId,
            location_name: locationName,
            location_address: address,
            location_country: country,
            location_currency: country === "Sweden" ? "SEK" : "EUR",
            location_latitude: finalCoords.lat,
            location_longitude: finalCoords.lon,
            location_contact_person: contactPerson,
            location_email: email,
            location_phone: phone,
            location_website: locationWebsite,
            opening_hours: openingHours,
            berry_prices: {
                country: {
                    [country]: {
                        currency: country === "Sweden" ? "SEK" : "EUR",
                        prices: prices ? prices : {
                            lingonberry: 0,
                            bilberry: 0
                        },
                        updated_info: {
                            updated_by: email
                        }
                    }
                }
            }
        };
        console.log(data);
        console.log(JSON.stringify(data));
        
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            });
            if (!response.ok) {
                setIsSubmitting(false);
                setIsError(true);
                console.log('HTTP error! response: ', response);
            } else {
                navigate(`/update/${organization.id}`);
            }
        } catch (error) {
            setIsSubmitting(false);
            setIsError(true);
            console.log('error creating location: ', error);
        }
    }

    const getCoords = (coords) => {
        setFinalCoords(coords);
    }

    useEffect(() => {
        if (country !== "" && berryPrices && berryPrices[country]) {
            setPrices(berryPrices[country].prices);
        }
        // eslint-disable-next-line
    }, [country])

    const handleHoursChange = (day, field, value) => {
        setOpeningHours(prevState => ({
            ...prevState,
            [day]: {
                ...prevState[day],
                [field]: value
            }
        }));
    };

    if (error) return <div>{t('something_went_wrong')}</div>;

    if (isLoading) {
        return (
            <div className="loading-container">
                <h1>{t('loading')}...</h1>
            </div>
        )
    }

    if (isAuth && !isLoading) return (
        <>
        <Helmet>
            <title>{t('create_location')} - Berryprice</title>
        </Helmet>
        <div className="create-location">
            <h1>{t('create_location')}</h1>
            <div className="card" style={{maxWidth: 600}}>
                <form autoComplete='off' onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                }}>
                    <h2>{t('name')}</h2>
                    <input autoComplete='off' className="create-input" type="text" required placeholder={t('location_name')} value={locationName} onChange={(e) => setLocationName(e.target.value)}></input>
                    <div className='line'></div>
                    <h2>{t('contact_info')}</h2>
                    <input autoComplete='off' className="create-input" type="text" required placeholder={t('website')} value={locationWebsite} onChange={(e) => setLocationWebsite(e.target.value)}></input>
                    <input autoComplete='off' className="create-input" type="text" required placeholder={t('contact_person')} value={contactPerson} onChange={(e) => setContactPerson(e.target.value)}></input>
                    <input autoComplete='off' className="create-input" type="email" required placeholder={t('email')} value={email} onChange={(e) => setEmail(e.target.value)}></input>
                    <input autoComplete='off' className="create-input" type="tel" pattern="^\+[0-9]{1,3}[1-9][0-9]+$" required placeholder={t('telephone_(e.g.') + " +46XXXXXXXXX)"} value={phone} onChange={(e) => setPhone(e.target.value)}></input>
                    <div className='line'></div>
                    <h2>{t('Position')}</h2>
                    <input autoComplete='off' className="create-input" type="text" required placeholder={t('address_(e.g.') + ' Exempelvägen X, XXX XX Exempelköping)'} value={address} onChange={(e) => getCoordsByAddress(e.target.value)}></input>
                    <p style={{marginTop: 0}}>{t('drag_the_map_to_change_position')}:</p>
                    <LocationMap coordinates={coords} isMapLoading={isMapLoading} sendCoords={getCoords}></LocationMap>
                    <select required className='create-input' name="country" id="country" value={country} onChange={(e) => handleOptionChange(e.target.value)}>
                        <option disabled value="">{t('select_country')}</option>
                        <option value="Sweden">{t('sweden')}</option>
                        <option value="Finland">{t('finland')}</option>
                    </select>
                    <div className='line'></div>
                    <h2>{t('Opening_hours')}</h2>
                    <div className='horizontal time' style={{justifyContent: 'space-between'}}>
                        <p style={{margin: 0}}>{t('Monday')}</p>
                        <div className='horizontal time-cont'>
                            <input type='time' className='create-input' style={{margin: 0}} max={openingHours.monday.to_time} onChange={(e) => handleHoursChange('monday', 'from_time', e.target.value)}></input>
                            <p style={{margin: '0 5px'}}>-</p>
                            <input type='time' className='create-input' style={{margin: 0}} min={openingHours.monday.from_time} onChange={(e) => handleHoursChange('monday', 'to_time', e.target.value)}></input>
                        </div>
                    </div>
                    <div className='horizontal time' style={{justifyContent: 'space-between'}}>
                        <p style={{margin: 0}}>{t('Tuesday')}</p>
                        <div className='horizontal time-cont'>
                            <input type='time' className='create-input' style={{margin: 0}} max={openingHours.tuesday.to_time} onChange={(e) => handleHoursChange('tuesday', 'from_time', e.target.value)}></input>
                            <p style={{margin: '0 5px'}}>-</p>
                            <input type='time' className='create-input' style={{margin: 0}} min={openingHours.tuesday.from_time} onChange={(e) => handleHoursChange('tuesday', 'to_time', e.target.value)}></input>
                        </div>
                    </div>
                    <div className='horizontal time' style={{justifyContent: 'space-between'}}>
                        <p style={{margin: 0}}>{t('Wednesday')}</p>
                        <div className='horizontal time-cont'>
                            <input type='time' className='create-input' style={{margin: 0}} max={openingHours.wednesday.to_time} onChange={(e) => handleHoursChange('wednesday', 'from_time', e.target.value)}></input>
                            <p style={{margin: '0 5px'}}>-</p>
                            <input type='time' className='create-input' style={{margin: 0}} min={openingHours.wednesday.from_time} onChange={(e) => handleHoursChange('wednesday', 'to_time', e.target.value)}></input>
                        </div>
                    </div>
                    <div className='horizontal time' style={{justifyContent: 'space-between'}}>
                        <p style={{margin: 0}}>{t('Thursday')}</p>
                        <div className='horizontal time-cont'>
                            <input type='time' className='create-input' style={{margin: 0}} max={openingHours.thursday.to_time} onChange={(e) => handleHoursChange('thursday', 'from_time', e.target.value)}></input>
                            <p style={{margin: '0 5px'}}>-</p>
                            <input type='time' className='create-input' style={{margin: 0}} min={openingHours.thursday.from_time} onChange={(e) => handleHoursChange('thursday', 'to_time', e.target.value)}></input>
                        </div>
                    </div>
                    <div className='horizontal time' style={{justifyContent: 'space-between'}}>
                        <p style={{margin: 0}}>{t('Friday')}</p>
                        <div className='horizontal time-cont'>
                            <input type='time' className='create-input' style={{margin: 0}} max={openingHours.friday.to_time} onChange={(e) => handleHoursChange('friday', 'from_time', e.target.value)}></input>
                            <p style={{margin: '0 5px'}}>-</p>
                            <input type='time' className='create-input' style={{margin: 0}} min={openingHours.friday.from_time} onChange={(e) => handleHoursChange('friday', 'to_time', e.target.value)}></input>
                        </div>
                    </div>
                    <div className='horizontal time' style={{justifyContent: 'space-between'}}>
                        <p style={{margin: 0}}>{t('Saturday')}</p>
                        <div className='horizontal time-cont'>
                            <input type='time' className='create-input' style={{margin: 0}} max={openingHours.saturday.to_time} onChange={(e) => handleHoursChange('saturday', 'from_time', e.target.value)}></input>
                            <p style={{margin: '0 5px'}}>-</p>
                            <input type='time' className='create-input' style={{margin: 0}} min={openingHours.saturday.from_time} onChange={(e) => handleHoursChange('saturday', 'to_time', e.target.value)}></input>
                        </div>
                    </div>
                    <div className='horizontal time' style={{justifyContent: 'space-between'}}>
                        <p style={{margin: 0}}>{t('Sunday')}</p>
                        <div className='horizontal time-cont'>
                            <input type='time' className='create-input' style={{margin: 0}} max={openingHours.sunday.to_time} onChange={(e) => handleHoursChange('sunday', 'from_time', e.target.value)}></input>
                            <p style={{margin: '0 5px'}}>-</p>
                            <input type='time' className='create-input' style={{margin: 0}} min={openingHours.sunday.from_time} onChange={(e) => handleHoursChange('sunday', 'to_time', e.target.value)}></input>
                        </div>
                    </div>
                    <div className='line'></div>
                    <div className="horizontal" style={{margin: "20px 0 0 0", justifyContent: "space-between"}}>
                        <button type='submit'>{t('create_location')}</button>
                        {isError && <p style={{color: '#ef3030', margin: 0}}>{t('something_went_wrong')}...</p>}
                        {isSubmitting && <p style={{margin: 0}}>{t('creating')}...</p>}
                    </div>
                </form>
            </div>
        </div>
        </>
    )
}